/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import { User } from "./user.model";
import ProductVariant from "./productVariant.model";
import UserBookmark from "./userBookmark.model";
import Product from "./product.model";
import Brand from "./brand.model";

export enum EUserCartItemSortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt"
}

export enum EUserCartItemStatus {
   enable = "enable",
   disable = "disable"
}

export interface IUserCart extends Model {
   ["info"]?: {
      ["quantity"]?: number;
   };

   ["$price"]?: any | null;

   ["__user"]?: User;
   ["__variant"]?: ProductVariant;
   ["__brand"]: Brand;
}

export default class UserCart extends Model<IUserCart> implements IUserCart {
   public ["info"]: {
      ["quantity"]: number;
   };

   public ["$price"]?: any | null;

   public ["__user"]: User;
   public ["__variant"]: ProductVariant;
   public ["__brand"]: Brand;
   public ["__product"]: Product;

   constructor(data?: IUserCart) {
      super(data);

      Object.assign(this, data);

      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }

      if (this.__variant && !(this.__variant instanceof ProductVariant)) {
         this.__variant = new ProductVariant(this.__variant);
      }

      if (this.__product && !(this.__product instanceof Product)) {
         this.__product = new Product(this.__product);
      }

      const getPrice = () => {
         let price = 0;

         if (!this.__variant?.info?.price && !this.__variant?.info?.priceAfterDiscount && !this?.info?.quantity) {
            return;
         } else if (this?.info?.quantity && this.__variant?.info?.price && !this.__variant?.info?.priceAfterDiscount) {
            price += this.__variant?.info?.price * this?.info?.quantity;
         } else if (this?.info?.quantity && this.__variant?.info?.priceAfterDiscount && this.__variant?.info?.price) {
            price += this.__variant?.info?.priceAfterDiscount * this?.info?.quantity;
         } else {
            return;
         }

         return price;
      };
      this.$price = getPrice();
   }

   // public static async paginate({
   //     page, limit, sort, sortBy
   // }: {
   //     page?: number,
   //     limit?: number,
   //     sort?: "asc" | "desc",
   //     sortBy?: "createdAt" | "updatedAt"
   // }) {
   //     try {
   //         const token = localStorage.getItem(tokenKey);
   //         if (!token) {
   //             return new ModelError({
   //                 ["httpCode"]: 401,
   //                 ["message"]: "Unauthorization",
   //                 ["errors"]: {
   //                     ["process"]: [
   //                         {
   //                             ["code"]: "process.error.401",
   //                             ["message"]: "Đăng nhập để tiếp tục."
   //                         }
   //                     ]
   //                 }
   //             });
   //         }
   //         const response = await fetch(`${apiDomain}/www/user/cart/paginate`, {
   //             ["method"]: "GET",
   //             // ["cache"]: "no-store",
   //             ["headers"]: {
   //                 ["X-Requested-With"]: "XMLHttpRequest",
   //                 ['Authorization']: `Bearer ${token}`,
   //                 ["page"]: page ? String(page) : "1",
   //                 ["limit"]: limit ? String(limit) : "10",
   //                 ["sort"]: sort ?? "desc",
   //                 ["sort-by"]: sortBy ?? "createdAt"
   //             }
   //         });

   //         if (!response.ok) {
   //             return new ModelError(await response.json() as TModelError);
   //         }

   //         const payload = await response.json() as TPayload<{
   //             ["paginator"]: TPaginate<IUserCart>;
   //         }>;

   //         const paginate = new Paginate(payload.data.paginator, UserCart);

   //         return paginate;
   //     }
   //     catch (error: any) {
   //         return new ModelError({
   //             ["httpCode"]: 500,
   //             ["message"]: error.message as string,
   //             ["errors"]: {
   //                 ["process"]: [
   //                     {
   //                         ["code"]: "process.error.5000",
   //                         ["message"]: "Process error on handling."
   //                     }
   //                 ]
   //             }
   //         });
   //     }
   // }

   public static async create(quantity: number, variantId: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục."
                     }
                  ]
               }
            });
         }
         const response = await fetch(`${apiDomain}/www/user/cart`, {
            ["method"]: "POST",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ["Authorization"]: `Bearer ${token}`
            },
            ["body"]: JSON.stringify({
               ["info.quantity"]: quantity,
               ["variantId"]: variantId
            })
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["newUserCartItem"]: IUserCart;
         }>;

         return new UserCart(payload.data.newUserCartItem);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async update({ offset, cartId, variantId }: { offset?: number; cartId: string; variantId: string }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục."
                     }
                  ]
               }
            });
         }
         const response = await fetch(`${apiDomain}/www/user/cart`, {
            ["method"]: "PATCH",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ["Authorization"]: `Bearer ${token}`,
               ["id"]: encodeURI(cartId)
            },
            ["body"]: JSON.stringify({
               ["offset"]: offset || 0,
               ["variantId"]: variantId
            })
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["updatedUserCartItem"]: IUserCart;
         }>;

         return new UserCart(payload.data.updatedUserCartItem);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async del(cartIds: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục."
                     }
                  ]
               }
            });
         }
         const response = await fetch(`${apiDomain}/www/user/cart`, {
            ["method"]: "DELETE",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["ids"]: encodeURI(cartIds)
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   // get $price() {
   //     let price = 0;

   //     if (!this.__variant.info?.price && !this.__variant.info.priceAfterDiscount && !this.info.quantity) {
   //         return
   //     } else if (this.info.quantity && this.__variant.info?.price && !this.__variant.info.priceAfterDiscount) {
   //         price += this.__variant.info?.price * this.info.quantity;
   //     } else if (this.info.quantity && this.__variant.info.priceAfterDiscount && this.__variant.info?.price) {
   //         price += this.__variant.info.priceAfterDiscount * this.info.quantity;
   //     } else {
   //         return
   //     }

   //     return price;
   // }
}

import { userInfoKey } from "@Config/index";
import { tokenKey } from "@Models/__variables";
import Cookies from "js-cookie";
import { isServer } from "utils";

// token
export function getTokenCache() {
   if (isServer) return null;
   return Cookies.get(tokenKey);
}
export function setTokenCache(token) {
   if (isServer) return null;
   localStorage.setItem(tokenKey, token);
   return Cookies.set(tokenKey, token, { expires: 365 / 2 });
}
export function removeTokenCache() {
   if (isServer) return null;
   localStorage.remove(tokenKey);
   return Cookies.remove(tokenKey);
}
// profile
export function getProfileCache() {
   if (isServer) return null;
   const profile = localStorage.getItem(userInfoKey);
   if (!profile) return null;
   return JSON.parse(profile);
}
export function setProfileCache(profile) {
   if (isServer) return null;
   localStorage.setItem(userInfoKey, JSON.stringify(profile));
   return true;
}
export function clearProfileCache() {
   if (isServer) return null;
   localStorage.removeItem(tokenKey);
   localStorage.removeItem(userInfoKey);
   return true;
}
export function clearAuthCache() {
   clearProfileCache();
   removeTokenCache();
}

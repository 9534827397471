import React, { useEffect } from "react";
import { connect } from "react-redux";

import Footer from "../footer";
import Header from "../header";
import { useRouter } from "next/router";
import { _metadata } from "../../utils/local-data";
import { useAppStoreContext } from "../AppStoreContext";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { gridBreakpoints } from "@Config/index";
import Headerv2 from "@Components/headerv2";

export const URL_ACCEPT_BUTTON_CONSULTING = ["/thiet-ke-noi-that", "/thi-cong-noi-that", "/quy-trinh-ket-noi"];

function Layout({ ...props }: any) {
   let prevScrollpos = 0;
   const { loading } = useAppStoreContext();
   const router = useRouter();

   useEffect(() => {
      // function isWhatPercentOf(x, y) {
      //    return (x / y) * 100;
      // }

      window.scrollTo({
         top: 0,
         left: 0,
      });
      prevScrollpos = window.pageYOffset;
      function handleScroll() {
         const btnScrollToTop = document.getElementById("btnScrollToTop");
         const currentScrollPos = window.pageYOffset;

         if (window.innerWidth < gridBreakpoints.md) {
            if (btnScrollToTop) {
               if (document.body.scrollTop > 180 || document.documentElement.scrollTop > 180) {
                  btnScrollToTop.style.display = "block";
                  if (
                     router.pathname.startsWith("/to-am-cua-toi/[communitySlug]") ||
                     router.pathname.startsWith("/cac-cong-ty-noi-that/[partnerSlug]") ||
                     router.pathname.startsWith("/cong-trinh-thuc-te/[portfolioSlug]") ||
                     router.pathname.startsWith("/thu-vien-thiet-ke/[designLibrarySlug]")
                  ) {
                     btnScrollToTop.style.bottom = "62px";
                  } else if (
                     router.pathname.startsWith("/to-am-cua-toi") ||
                     router.pathname.startsWith("/feed") ||
                     router.pathname.startsWith("/post") ||
                     router.pathname === "/" ||
                     router.pathname.startsWith("/hoi-dap/[_qnaId]") ||
                     router.pathname.startsWith("/tai-khoan")
                  ) {
                     btnScrollToTop.style.bottom = "60px";
                  } else if (URL_ACCEPT_BUTTON_CONSULTING.includes(router.pathname)) {
                     btnScrollToTop.style.bottom = "64px";
                  } else {
                     btnScrollToTop.style.bottom = "16px";
                  }
               } else {
                  btnScrollToTop.style.display = "none";
               }
            }
         } else {
            if (btnScrollToTop) {
               if (document.body.scrollTop > 180 || document.documentElement.scrollTop > 180) {
                  btnScrollToTop.style.display = "block";
                  if (router.pathname.startsWith("/to-am-cua-toi") || router.pathname.startsWith("/hoi-dap/[_qnaId]")) {
                     btnScrollToTop.style.bottom = "60px";
                  } else {
                     btnScrollToTop.style.bottom = "16px";
                  }
               } else {
                  btnScrollToTop.style.display = "none";
               }
            }
         }
         prevScrollpos = currentScrollPos;
      }

      window.addEventListener("scroll", handleScroll);
      return () => window.addEventListener("scroll", handleScroll);
   }, [router]);

   const disableHeader =
      router.pathname.includes("/chuong-trinh-gioi-thieu-ban") || router.pathname.includes("dang-bai") || router.pathname.includes("email-expired");

   const disableFooter =
      router.pathname.includes("/chuong-trinh-gioi-thieu-ban") ||
      router.pathname.includes("cau-hoi-khao-sat") ||
      router.pathname.includes("dang-bai") ||
      router.pathname.includes("email-expired") ||
      router.pathname.includes("tai-khoan") ||
      router.pathname.startsWith("/to-am-cua-toi") ||
      router.pathname.startsWith("/cong-trinh-thuc-te") ||
      router.pathname.startsWith("/thu-vien-thiet-ke") ||
      router.pathname.startsWith("/thu-vien-anh") ||
      router.pathname.startsWith("/hoi-dap") ||
      router.pathname.startsWith("/search") ||
      router.pathname.startsWith("/[findIdeaSlug]") ||
      router.pathname.startsWith("/feed/hashtag/[hashtag]") ||
      (isMobile && router.asPath.startsWith("/shop/gio-hang"));

   return (
      <>
         {/* <div className="d-flex flex-column min-screen-height-100 main-container"> */}
         {/* Header */}
         {props.menu && router.query.isApp !== "true" && !disableHeader ? <Headerv2 {...props} /> : undefined}

         {/* Content children */}
         <div className={classNames("content-page", router.asPath?.split("/")[1])}>{props?.children}</div>

         {/* Footer */}
         {!loading && <div className="">{router.query.isApp !== "true" && !disableFooter && <Footer {...props} />}</div>}
         {!loading && (
            <div className="">
               {router.query.isApp !== "true" && (router.asPath === "/feed/gioi-thieu-cong-dong" || router.asPath === "/feed/cau-hoi-thuong-gap") && (
                  <Footer {...props} />
               )}
            </div>
         )}
         {/* </div> */}
      </>
   );
}
export default connect((props: any) => props)(Layout);

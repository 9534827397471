import { initPaginate } from "models/model";
import * as types from "./types";
import GeneralMediasBySpace from "@Models/generalMediasBySpace.model";

interface ISearchState {
   homeGalleries: { [t: string]: GeneralMediasBySpace[] }
}

const initState: ISearchState = {
   homeGalleries: {}
};

export default function homeReducer(state = initState, action): ISearchState {
   const { type, payload } = action;
   switch (type) {
      case types.SET_HOME_GALLERIES:
         return {
            ...state,
            homeGalleries: {
               ...state.homeGalleries,
               [payload.slug]: payload.data
            }
         };

      default:
         return state;
   }
}

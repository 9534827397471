import * as env from "@Config/__env";

export const apiDomain = env.apiDomain
export const appDomain = env.appDomain

export const isDev = process.env.NODE_ENV !== "production"
export const isStaging = env.envMode === "staging"

export const G_SITE_KEY = env.G_SITE_KEY

export const tokenKey = "userToken";

const contacts = {
   zalo: "https://zalo.me/1171954959320860843",
   facebook: "https://www.facebook.com/spacetvn",
   messenger: "https://www.messenger.com/t/649707212106005",
   tiktok: "https://www.tiktok.com/@spacet_vn",
   instagram: "https://www.instagram.com/spacet_vn",
   youtube: "https://www.youtube.com/SpaceT",
   pinterest: "https://www.pinterest.com/2wt3u757irqbvxdzk53vqh2svshwzq",
   email: "info@spacet.vn",
   github: "",
   phone: "084-884-7938",
   address: {
      "abc": "abc"
   }
};

const app_store = {
   android: "https://play.google.com/store/apps/details?id=com.spacet.mobile",
   ios: "https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398",
};


const envoriment = {
   ["env"]: env.envMode,
   ["apiDomain"]: apiDomain,
   ["appDomain"]: appDomain,
   ["g_site_key"]: G_SITE_KEY,
   ["tokenKey"]: tokenKey,
   ["contacts"]: contacts,
   ["app_store"]: app_store,
};


export default envoriment 

import MenuItem from "models/menuItem.model";
import { memo, SetStateAction, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "@Components/Link";
import classNames from "classnames";
import Menu from "models/menu.model";

interface IProps {
   visible?: boolean;
   menus: MenuItem[];
   onClose?: () => void;
}
interface IState {
   collapseLv0: { [t: string]: boolean };
   actives: string[];
   menus: MenuItem[];
}

function ShopMenuDrawerMobile({ visible, onClose, menus: menuProps }: IProps) {
   const router = useRouter();

   const [state, setstate] = useState<IState>({
      collapseLv0: {
         m1: true,
         "61a12efcd348d1719d8d1b26": true,
         "61a1332bd348d1719d8d1b32": true,
      },
      actives: [],
      menus: [],
   });
   function setState(newS: Partial<IState> | SetStateAction<IState>) {
      if (typeof newS === "function") {
         setstate(newS);
      }
      setstate((prev) => ({ ...prev, ...newS }));
   }

   // Initial menu state
   useEffect(() => {
      setState({
         menus: [
            ...menuProps,
            {
               _id: "m1",
               menuId: "619c6d817169da6d9389f4cb",
               parentId: null,
               info: { title: "Dịch vụ khác", slug: "", position: 0 },
               xpath: "m1",
               moreParentIds: [],
               fullSlug: "",
               fullUrl: "",
               lv: 0,
               __childs: [
                  {
                     _id: "m11",
                     menuId: "619c6d817169da6d9389f4cb",
                     parentId: "m1",
                     info: { title: "Nội thất giá sỉ", slug: "noithatgiasi", position: 0 },
                     xpath: "m1;;m11",
                     moreParentIds: [],
                     __childs: [],
                     fullSlug: "noithatgiasi",
                     fullUrl: "noithatgiasi",
                     lv: 0,
                  },
                  {
                     _id: "m12",
                     menuId: "619c6d817169da6d9389f4cb",
                     parentId: "m1",
                     info: { title: "Tiếp thị liên kết", slug: "chuong-trinh-lien-ket", position: 0 },
                     xpath: "m1;;m12",
                     moreParentIds: [],
                     __childs: [],
                     fullSlug: "",
                     fullUrl: "chuong-trinh-lien-ket",
                     lv: 0,
                  },
                  {
                     _id: "m13",
                     menuId: "619c6d817169da6d9389f4cb",
                     parentId: "m1",
                     info: { title: "Đối tác nội thất", slug: "https://partner.spacet.vn/", position: 0 },
                     xpath: "m1;;m13",
                     moreParentIds: [],
                     __childs: [],
                     fullSlug: "",
                     fullUrl: "https://partner.spacet.vn/",
                     target: "_blank",
                     lv: 0,
                  },

                  {
                     _id: "m14",
                     menuId: "619c6d817169da6d9389f4cb",
                     parentId: "m1",
                     info: { title: "Kênh người bán", slug: "chinh-sach/ban-hang-cung-space-t", position: 0 },
                     xpath: "m1;;m14",
                     moreParentIds: [],
                     __childs: [],
                     fullSlug: "chinh-sach/ban-hang-cung-space-t",
                     fullUrl: "chinh-sach/ban-hang-cung-space-t",
                     lv: 0,
                  },
               ],
            } as any,
         ],
      });
   }, [menuProps]);

   useEffect(() => {
      // const firstPath = router.asPath.split("/")[1];
      // let slug;
      // if (firstPath === "shop") {
      //    slug = router.asPath.split("/")[2];
      // } else if (firstPath === "blog") {
      //    slug = router.asPath.split("/")[2];
      // } else {
      //    slug = router.asPath.split("/")[1];
      // }
      const menuItem = Menu.findItemWithSlugV2(state.menus, router.asPath);
      if (menuItem) {
         setState({
            actives: menuItem?.xpath?.split(";;"),
         });
      }
   }, [router.asPath, state.menus]);
   useEffect(() => {
      setState({
         collapseLv0: {
            m1: true,
            "61a12efcd348d1719d8d1b26": true,
            "61a1332bd348d1719d8d1b32": true,
         },
      });
   }, [router.asPath]);

   // For drawer scroll
   const [init, setIsInit] = useState(true);
   useEffect(() => {
      if (init) {
         setIsInit(false);
         return;
      }
      if (visible) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "";
      }
   }, [init, visible]);

   const handleClose = () => {
      onClose?.();
   };

   const onClickMenuLv0 = (id) => {
      if (state.collapseLv0[id]) {
         setState({
            collapseLv0: { ...state.collapseLv0, [id]: false },
         });
      } else {
         setState({
            collapseLv0: { ...state.collapseLv0, [id]: true },
         });
      }
   };
   const onClickMenuLink = (menu: MenuItem) => {
      handleClose();
      if (menu.__childs && menu.__childs.length > 0) {
      } else {
         setState({ actives: menu.xpath.split(";;") });
      }
   };

   return (
      <>
         <div className={classNames("drawer-backdrop", visible ? "showBackdrop" : "hideBackdrop")} onClick={handleClose}></div>
         <div className={`drawer-menu-shopv2 ${visible ? "showMenu" : "hideMenu"}`}>
            <div className="shop-menu-content">
               <div className="menu-header">
                  <div className="close-icon" onClick={handleClose}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                           d="M12.0007 10.5857L16.9507 5.63574L18.3647 7.04974L13.4147 11.9997L18.3647 16.9497L16.9507 18.3637L12.0007 13.4137L7.05072 18.3637L5.63672 16.9497L10.5867 11.9997L5.63672 7.04974L7.05072 5.63574L12.0007 10.5857Z"
                           fill="#2F2B43"
                        />
                     </svg>
                  </div>
                  <span>Danh mục</span>
               </div>
               <div className="wrap-menu-list">
                  {state.menus.map((menu) => {
                     const isCollapse = state.collapseLv0[menu._id];
                     const active = state.actives.includes(menu._id);
                     const isChilds = menu.__childs && menu.__childs.length > 0;
                     const index = 1;
                     if (menu.info.title === "Tổng quan") return;

                     const _url = menu.fullUrl?.startsWith("http") ? menu.fullUrl : "/" + menu.fullUrl || "";
                     return (
                        <div key={menu._id} className="menu-list-lv0" data-menuid={menu._id}>
                           <div className="menu-item-lv0" key={menu._id}>
                              <Link
                                 href={_url}
                                 target={menu.target}
                                 className={classNames("menu-link fs-16 fw-500", active ? "text-primary" : "text")}
                                 onClick={() => onClickMenuLink(menu)}
                              >
                                 <span>{menu.info.title}</span>
                              </Link>
                              {isChilds && (
                                 <span
                                    className="icon"
                                    onClick={(e) => {
                                       onClickMenuLv0(menu._id);
                                    }}
                                 >
                                    {isCollapse ? (
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M12 14L8 10H16L12 14Z" fill="#2F2B43" />
                                       </svg>
                                    ) : (
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M14 12L10 16V8L14 12Z" fill="#2F2B43" />
                                       </svg>
                                    )}
                                 </span>
                              )}
                           </div>
                           {menu.__childs && menu.__childs.length > 0 && (
                              <MenuList
                                 key={menu._id}
                                 index={index}
                                 data={menu}
                                 actives={state.actives}
                                 style={{ display: isCollapse ? "block" : "none" }}
                                 onClickMenuLink={onClickMenuLink}
                              />
                           )}
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
      </>
   );
}

export default memo(ShopMenuDrawerMobile, (prev, next) => {
   return prev.visible === next.visible;
});

function MenuList({
   data,
   index,
   onClickMenuLink,
   actives,
   style,
   ...props
}: {
   index: number;
   data: MenuItem;
   onClickMenuLink?: (p: any) => void;
   actives: string[];
   style: any;
}) {
   const [isCollapses, setIsCollapse] = useState<{ [t: string]: boolean }>({});
   return (
      <div className={classNames("menu-list-lv1")} style={style}>
         {data.__childs.map((menu) => {
            const isCollapse = isCollapses[menu._id];
            const active = actives.includes(menu._id);
            const indexlv1 = index + 1;
            if (menu.info.title === "Tổng quan") return;
            if (menu.__childs && menu.__childs.length > 0) {
               return (
                  <div className="menu-item-lv1" key={menu._id}>
                     <ComMenuItem
                        key={menu._id}
                        active={active}
                        index={indexlv1}
                        isCollapse={isCollapse}
                        data={menu}
                        onClickMenuLink={onClickMenuLink}
                        onClickCollabse={(arg) => {
                           setIsCollapse((prev) => ({
                              ...prev,
                              [menu._id]: !isCollapse,
                           }));
                        }}
                     />
                     <MenuList
                        data={menu}
                        actives={actives}
                        index={indexlv1}
                        style={{ display: isCollapse ? "block" : "none" }}
                        onClickMenuLink={onClickMenuLink}
                     />
                  </div>
               );
            }
            return <ComMenuItem key={menu._id} data={menu} index={indexlv1} active={active} onClickMenuLink={onClickMenuLink} />;
         })}
      </div>
   );
}

function ComMenuItem({
   isCollapse,
   data,
   index,
   active,
   onClickMenuLink,
   onClickCollabse,
   ...props
}: {
   isCollapse?: boolean;
   index: number;
   active: boolean;
   data: MenuItem;
   onClickMenuLink?: (p: any) => void;
   onClickCollabse?: (p: any) => void;
}) {
   const isChilds = data.__childs && data.__childs.length > 0;
   const _url = data.fullUrl?.startsWith("http") ? data.fullUrl : "/" + data.fullUrl || "";
   return (
      <div className={classNames("menu-item-n")} data-menuid={data._id} style={{ paddingLeft: 16 * index }} {...props}>
         <Link
            className="menu-link"
            href={_url}
            target={data.target}
            onClick={(e) => {
               onClickMenuLink?.(data);
            }}
         >
            <span className={classNames("fs-14", active ? "text-primary fw-500" : "text")}>{data.info.title}</span>
         </Link>
         {isChilds && (
            <span
               onClick={(e) => {
                  onClickCollabse?.(data);
                  // if (isChilds) {
                  //    e.preventDefault();
                  //    e.stopPropagation();
                  // }
               }}
            >
               {isCollapse ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path d="M12 14L8 10H16L12 14Z" fill="#2F2B43" />
                  </svg>
               ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path d="M14 12L10 16V8L14 12Z" fill="#2F2B43" />
                  </svg>
               )}
            </span>
         )}
      </div>
   );
}

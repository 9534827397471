import Script from "next/script";
import { useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";
import ScriptLazy, { PageView } from "utils/Script";

export default function NextScriptCustom() {
   useEffect(() => {
      // const bot = new TeleBot();
      // function testAPI() {
      //    window.FB.api("/me", function (response) {
      //       console.log("Successful login for: " + response.name);
      //    });
      //    // window.FB.api("/me", { fields: "name, email" }, function (response) {
      //    //    console.log("response", response);
      //    // });
      // }

      // function statusChangeCallback(response) {
      //    bot.sendMessage("statusChangeCallback ", JSON.stringify(response));
      //    console.log("statusChangeCallback", response);
      //    if (response.status === "connected") {
      //       testAPI();
      //    }
      // }

      window.fbAsyncInit = function () {
         if (!window.FB) {
            console.info("window.FB not found");
            return;
         }
         window.FB?.init({
            appId: "711063823782715",
            cookie: true,
            xfbml: true,
            version: "v20.0",
         });
         window.FB?.logPageView();
         window.FB?.getLoginStatus(function (response) {
            // statusChangeCallback(response); // Returns the login status.
         });
      };
   }, []);
   return (
      <>
         {/* <PageView />
         <ScriptLazy /> */}
         {/* Gtag manager */}
         <Script
            id="gtag-code"
            async
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtm.js?id="${process.env.NEXT_PUBLIC_GA_TRACKING_ID}"`}
         ></Script>
         <Script id="google-tag-manager" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
               new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
               j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
               'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
               })(window,document,'script','dataLayer',"${process.env.NEXT_PUBLIC_GA_TRACKING_ID}");
            `}
         </Script>

         <Script async defer src="https://connect.facebook.net/en_US/sdk.js" crossOrigin="anonymous"></Script>
         <Script defer id="facebook-jssdk" src="https://connect.facebook.com/en_US/sdk.js"></Script>

         {/* facebook */}
         {/* <Script
            id="pageview"
            strategy="afterInteractive"
            type="text/javascript"
            dangerouslySetInnerHTML={{
               __html: `function fbscript(f,b,e,v,n,t,s)
                     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                     n.queue=[];t=b.createElement(e);t.async=!0;
                     t.src=v;s=b.getElementsByTagName(e)[0];
                     s.parentNode.insertBefore(t,s)};fbscript(window, document,'script',
                     'https://connect.facebook.net/en_US/fbevents.js');
                     fbq('init', '1055278889045260');
                     fbq('track', 'PageView');`,
            }}
         ></Script>
         <noscript>
            <img height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=1055278889045260&ev=PageView&noscript=1" />
         </noscript>

         <Script id="fbsdk" strategy="lazyOnload" async type="text/javascript" defer src="https://connect.facebook.net/en_US/sdk.js"></Script> 
         <Script strategy="lazyOnload" id="facebook-jssdk" async src="//connect.facebook.net/en_US/all.js"></Script> 

         <Script
            id="fbasync"
            strategy="lazyOnload"
            type="text/javascript"
            async
            dangerouslySetInnerHTML={{
               __html: `
                        window.fbAsyncInit = function () {
                        FB.init({
                           appId: "711063823782715",
                           cookie: true,
                           xfbml: true,
                           version: 'v2.0'
                        });
                        FB.getLoginStatus(function (response) {
                           statusChangeCallback(response);
                        });
                     };
                     (function (d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s);
                        js.id = id;
                        js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
                        fjs.parentNode.insertBefore(js, fjs);
                     }(document, 'script', 'facebook-jssdk'));

                     function statusChangeCallback(response) {
                        if (response.status === 'connected') {
                           console.log('Welcome! Fetching your information.... ');
                           FB.api('/me', function (response) {
                           console.log('Successful login for: ' + response.name);
                           console.log('Thanks for logging in, ' + response.name + '!')
                           });
                        } else {
                           console.log("FB login do not allow")

                        }
                     }`,
            }}
         ></Script> */}

         {/* <Script id="renderbtn" strategy="lazyOnload" src="https://apis.google.com/js/platform.js?onload=renderButton" async defer></Script> */}

         {/* Apple login */}
         {/* <Script
            id="appleid"
            strategy="lazyOnload"
            async
            defer
            type="text/javascript"
            src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
         ></Script>
         <Script
            id="applecb"
            strategy="afterInteractive"
            async
            defer
            type="text/javascript"
            dangerouslySetInnerHTML={{
               __html: 
                  process.env.RUN_ENV === "develop"
                     ? `window.addEventListener("load", e => {
                           if(window.AppleID){window.AppleID.auth.init({
                              clientId: "com.spacet.app",
                              scope: 'name email',
                              redirectURI: `${appDomain}/apple.callback`,
                              usePopup: true //or false defaults to false
                           })}
                        })`
                     : `window.addEventListener("load", e => {
                           if(window.AppleID){window.AppleID.auth.init({
                              clientId: "com.spacet.web",
                              scope: 'name email',
                              redirectURI: "${appDomain}/apple.callback",
                              usePopup: true //or false defaults to false
                           })}
                        })`,
            }}
         ></Script> */}

         {/* heatmap for visualize user behavior */}
         {(!isMobile || isIOS) && (
            <Script
               id="heatmap"
               async
               defer
               dangerouslySetInnerHTML={{
                  __html: `
                        (function (h, e, a, t, m, p) {
                           m = e.createElement(a); 
                           m.async = !0;
                           m.src = t;
                           p = e.getElementsByTagName(a)[0];
                           p.parentNode.insertBefore(m, p);
                        })(window, document, 'script', 'https://u.heatmap.it/log.js');`,
               }}
            ></Script>
         )}

         {/* Core dash script */}
         {/* <Script id="coredash" strategy="afterInteractive" src="https://rum.corewebvitals.io/cwv/661ca5d1c18a61f4fb757938.js" />
         <Script id="wincoredas" strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: `window.__CWVL='mypagelabel';` }}></Script> */}
      </>
   );
}

/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import Cookies from "js-cookie";

/**
 * Core(s)
 */
import ActualWorkComment, { IActualWorkComment } from "./actualWorkComment.model";
import ActualWorkReaction, { IActualWorkReaction } from "./actualWorkReaction.model";
import ArticleComment, { IArticleComment } from "./articleComment.model";
import ArticleReaction, { IArticleReaction } from "./articleReaction.model";
import Community, { ICommunity } from "./community.model";
import CommunityComment, { ICommunityComment } from "./communityComment.model";
import CommunityReaction, { ICommunityReaction } from "./communityReaction.model";
import Model, { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import Order, { IOrder, EOrderStatus as StatusOrder } from "./order.model";
import { EOptionStatus, IQnA, QnA } from "./qna.model";
import { QnAImage } from "./qnaImage.model";
import SocialFeed, { ISocialFeed } from "./socialFeed.model";
import UserMedia from "./userMedia.model";
import UserPoint, { TUserPoint } from "./userPoint.model";
import UserSubscribe from "./userSubscribe.model";
import store from "state";
import { signin } from "state/action/auth";
import { cacheFirebaseLocalToken } from "config/firebase";
import { getLocalIP } from "utils";
import { removeTokenCache, setTokenCache } from "utils/auth";
import TeleBot from "./teleBot";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}
export enum EMethod {
   email = "email",
   sms = "sms",
}
export enum EType {
   none = "none",
   google = "google",
   facebook = "facebook",
}
export enum EGender {
   male = "male",
   female = "female",
   others = "others",
}
export enum EOTPType {
   signup = "signup",
   update = "update",
   delete = "delete",
}
export enum EBadge {
   creator = "creator",
   expert = "expert",
   enterprise = "enterprise",
   none = "None",
}
export enum EBadgeText {
   creator = "Nhà sáng tạo",
   expert = "Chuyên gia",
   enterprise = "Doanh nghiệp",
   none = "None",
}

export interface IUserSettings {
   badge?: {
      display?: boolean;
   };
   browser: {
      chrome: boolean;
   };
   notifications: {
      feed: {
         comment: boolean;
         like: false;
         newFollow: boolean;
         newPost: boolean;
      };
      coin: boolean;
      order: boolean;
   };
}

export interface IUser extends Model {
   authInfo?: {
      openId?: string | null;
      password?: string | null;
   };
   publicInfo?: {
      name?: {
         first?: string | null;
         middle?: string | null;
         last?: string | null;
      };
      nickname?: string | null;
      email?: string | null;
      gender?: EGender | null;
      dateOfBirth?: string | Date | null;
      phone?: string | null;
      intro?: string | null;
      link?: string | null;
      badge?: EBadge | null;
   };
   verification?: {
      email?: boolean | null;
      phone?: boolean | null;
   };
   options?: {
      status?: EStatus;
      type?: EType;
   };
   settings?: IUserSettings;
   __images?: QnAImage[];
   __media?: UserMedia;
   __feedCount: number;
   __subscribingCount?: number;
   __subscribedCount?: number;
   __subscribedByCurrentUser?: UserSubscribe | null;
   __subscribedCurrentUser?: UserSubscribe | null;
}
export class User extends Model<IUser> implements IUser {
   public "authInfo"!: {
      openId?: string | null;
      password?: string | null;
   };
   public "publicInfo"!: {
      name?: {
         first?: string;
         middle?: string;
         last?: string;
      };
      nickname?: string;
      email?: string;
      gender?: EGender;
      dateOfBirth?: string;
      phone?: string;
      intro?: string;
      link?: string;
      badge?: EBadge;
   };
   public "verification"?: {
      email?: boolean;
      phone?: boolean;
   };
   public "options"!: {
      status?: EStatus;
      type?: EType;
   };
   public "settings"?: IUserSettings;

   public "__images": QnAImage[];
   public "__media": UserMedia;
   public "__feedCount": number;
   public "__subscribingCount"?: number;
   public "__subscribedCount"?: number;
   public "__subscribedByCurrentUser"?: UserSubscribe;
   public "__subscribedCurrentUser"?: UserSubscribe;

   constructor(data?: IUser) {
      super(data);
      Object.assign(this, data);
      if (this.__media && !(this.__media instanceof UserMedia)) {
         this.__media = new UserMedia(this.__media);
      }
   }

   public static async signupVerify(account: string, captchat: string) {
      try {
         if (!captchat) {
            return new ModelError({
               httpCode: 401,
               message: "Đăng kỹ lỗi, vui lòng liên hệ bộ phận hỗ trợ.",
            });
         }
         const localIP = await getLocalIP();
         const response = await fetch(`${apiDomain}/www/user/signup/verify/v2`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ...(!localIP ? undefined : { ["x-secret-for"]: localIP }),
               captchat,
            },
            body: JSON.stringify({
               account: account,
            }),
         });

         if (!response.ok) {
            return new ModelError(await response.json());
         }
         const payload = (await response.json()) as TPayload<{ message: string; otpId: string }>;

         return payload.data;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signin(account: string, password: string) {
      try {
         const localIP = await getLocalIP();
         const deviceToken = localStorage.getItem(cacheFirebaseLocalToken);

         const response = await fetch(`${apiDomain}/www/user/signin`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               account: account,
               password: password,
               ...(localIP ? { "device.id": localIP } : undefined),
               ...(deviceToken ? { "device.token": deviceToken } : undefined),
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            token: string;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;

         const signinData = {
            userInfo: new User(payload.data.userInfo),
            token: payload.data.token,
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };

         if (typeof window !== "undefined") {
            // localStorage.setItem(tokenKey, signinData.token);
            // Cookies.set(tokenKey, signinData.token, {
            //    expires: 365 / 2,
            // });
            setTokenCache(signinData.token);
            store.dispatch(signin(signinData));
         }
         return signinData;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            firstMessageErr: "Process error on handling",
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signinWithFaceBook(token: string, registerFromURL?: string) {
      try {
         const localIP = await getLocalIP();
         const deviceToken = localStorage.getItem(cacheFirebaseLocalToken);

         // const tracker = window.ga?.getAll()[0];
         const gaClientId = 1; // tracker.get("clientId");
         const response = await fetch(`${apiDomain}/www/user/signin/facebook`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               token: token,
               gaClientId: gaClientId,
               registerFromUrl: registerFromURL ? registerFromURL : typeof window !== undefined ? window.location.href : undefined,
               ...(localIP ? { "device.id": localIP } : undefined),
               ...(deviceToken ? { "device.token": deviceToken } : undefined),
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            token: string;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;
         const signinData = {
            userInfo: new User(payload.data.userInfo),
            token: payload.data.token,
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };

         if (typeof window !== "undefined") {
            // localStorage.setItem(tokenKey, signinData.token);
            // Cookies.set(tokenKey, signinData.token, {
            //    expires: 365 / 2,
            // });
            setTokenCache(signinData.token);
            store.dispatch(signin(signinData));
         }

         return signinData;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signinWithGoogle(token: string, registerFromURL?: string) {
      try {
         const localIP = await getLocalIP();
         const deviceToken = localStorage.getItem(cacheFirebaseLocalToken);

         const tracker = window?.ga?.getAll()[0];
         const gaClientId = tracker.get("clientId");
         const response = await fetch(`${apiDomain}/www/user/signin/google`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               token: token,
               gaClientId: gaClientId,
               registerFromUrl: registerFromURL ? registerFromURL : typeof window !== undefined ? window.location.href : undefined,
               ...(localIP ? { "device.id": localIP } : undefined),
               ...(deviceToken ? { "device.token": deviceToken } : undefined),
            }),
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            token: string;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;
         const signinData = {
            userInfo: new User(payload.data.userInfo),
            token: payload.data.token,
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };

         if (typeof window !== "undefined") {
            // localStorage.setItem(tokenKey, signinData.token);
            // Cookies.set(tokenKey, signinData.token, {
            //    expires: 365 / 2,
            // });
            setTokenCache(signinData.token);

            store.dispatch(signin(signinData));
         }

         return signinData;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signinWithApple(token: string, registerFromURL?: string) {
      try {
         const localIP = await getLocalIP();
         const deviceToken = localStorage.getItem(cacheFirebaseLocalToken);
         // const tracker = window.ga?.getAll()[0];
         const gaClientId = 1; // tracker.get("clientId");
         const response = await fetch(`${apiDomain}/www/user/signin/apple`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               isWeb: true,
               token: token,
               gaClientId: gaClientId,
               registerFromUrl: registerFromURL ? registerFromURL : typeof window !== undefined ? window.location.href : undefined,
               ...(localIP ? { "device.id": localIP } : undefined),
               ...(deviceToken ? { "device.token": deviceToken } : undefined),
            }),
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            token: string;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;
         const signinData = {
            userInfo: new User(payload.data.userInfo),
            token: payload.data.token,
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };

         if (typeof window !== "undefined") {
            // localStorage.setItem(tokenKey, signinData.token);
            // Cookies.set(tokenKey, signinData.token, {
            //    expires: 365 / 2,
            // });
            setTokenCache(signinData.token);
            store.dispatch(signin(signinData));
         }

         return signinData;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signup(
      account: string,
      authCode: number,
      // nickname: string,
      password?: string,
      confirmPassword?: string,
      registerFromURL?: string,
      captchat?: string
   ) {
      try {
         // const tracker = window.ga?.getAll()[0];
         // const gaClientId = tracker.get("clientId");
         const tracker = window.gapi?.K;
         const gaClientId = tracker;
         const response = await fetch(`${apiDomain}/www/user/signup`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ...(captchat ? { ["captchat"]: captchat } : undefined),
            },
            body: JSON.stringify({
               account: account,
               authCode: authCode ?? 123123,
               // "nickname": nickname,
               password: password,
               confirmPassword: password,
               gaClientId: gaClientId,
               registerFromUrl: registerFromURL ? registerFromURL : typeof window !== undefined ? window.location.href : undefined,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = (await response.json()) as TPayload<{
            newUser: IUser;
            newUserPoint: UserPoint;
         }>;

         return new User(payload.data.newUser);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async verify() {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Unauthorization",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const localIP = await getLocalIP();
         const deviceToken = localStorage.getItem(cacheFirebaseLocalToken) || "";
         const response = await fetch(`${apiDomain}/www/user/verify`, {
            method: "GET",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["device.id"]: localIP,
               ["device.token"]: deviceToken,
            },
         });

         if (!response.ok) {
            removeTokenCache();
            const error = (await response.json()) as TModelError;
            TeleBot.sendMessage("Verify user error", error);
            return new ModelError(error);
         }

         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;

         return {
            userInfo: new User(payload.data.userInfo),
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };
      } catch (error) {
         console.error(error);
         TeleBot.sendMessage("Verify user error", error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async verifyEmail() {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Unauthorization",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/verify-email`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;

         return {
            userInfo: new User(payload.data.userInfo),
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async verifyEmailByUserId(id: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Unauthorization",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/verify-email/by-user-id`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               id: String(id),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            userInfo: IUser;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         }>;

         return {
            userInfo: new User(payload.data.userInfo),
            userPoints: payload.data.userPoints,
            userNotifications: payload.data.userNotifications,
            userBookmarks: payload.data.userBookmarks,
            userStatus: payload.data.userStatus,
         };
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async signout() {
      try {
         const token = localStorage.getItem(tokenKey);
         const notiToken = localStorage.getItem(cacheFirebaseLocalToken);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Lỗi, đăng xuất không thành công",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng xuất không thành công.",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/signout`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ["Authorization"]: `Bearer ${token}`,
            },
            body: JSON.stringify({
               ...(notiToken ? { "device.id": notiToken } : undefined),
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return this;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async update(formdata: {
      birth?: {
         day: number | null;
         month: number | null;
         year: number | null;
      };
      gender?: EGender | null;
      firstName?: string | null;
      lastName?: string | null;
      nickname?: string | null;
      link?: string | null;
      bio?: string | null;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Unauthorization",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user`, {
            method: "PATCH",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ["Authorization"]: `Bearer ${token}`,
            },
            body: JSON.stringify(formdata),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            updatedUser: IUser;
         }>;

         return new User(payload.data.updatedUser);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "Lỗi, đăng xuất không thành công",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Đăng xuất không thành công.",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/change-password`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ["Authorization"]: `Bearer ${token}`,
            },
            body: JSON.stringify({
               ...(oldPassword ? { oldPassword: oldPassword } : undefined),
               newPassword: newPassword,
               confirmNewPassword: confirmPassword,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return this;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getQnAPaginate({
      page,
      limit,
      sort,
      sortBy,
      option,
   }: {
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "info.publishedAt" | "info.lastCommentAt" | "createdAt";
      option?: EOptionStatus.answered | EOptionStatus.owned;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);

         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "You don't have permission to access this API.",
               errors: {
                  process: [
                     {
                        code: "process.error.401",
                        message: "Loading fail",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/qna/paginate`, {
            method: "GET",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               page: !page ? String(1) : String(page),
               limit: !limit ? String(20) : String(limit),
               sort: sort ?? "desc",
               "sort-by": sortBy ?? "createdAt",
               option: option ?? EOptionStatus.owned,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            paginator: TPaginate<IQnA>;
         }>;

         return new Paginate(payload.data.paginator, QnA);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getOrderPaginate({
      page,
      limit,
      sort,
      sortBy,
      status,
   }: {
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt";
      status?: StatusOrder;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);

         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "401 Unauthorized error",
               errors: {
                  process: [
                     {
                        code: "401",
                        message: "401 Unauthorized error",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/order/paginate`, {
            method: "GET",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${localStorage.getItem("userToken")}`,
               page: !page ? String(1) : String(page),
               limit: !limit ? String(20) : String(limit),
               sort: sort || "desc",
               "sort-by": sortBy || "createdAt",
               ...(!status ? undefined : { status: status }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            paginator: TPaginate<IOrder>;
         }>;

         const paginate = new Paginate(payload.data.paginator, Order);

         return paginate;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getOrderById(id: string) {
      try {
         const token = localStorage.getItem(tokenKey);

         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "401 Unauthorized error",
               errors: {
                  process: [
                     {
                        code: "401",
                        message: "401 Unauthorized error",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/user/order`, {
            method: "GET",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               id: encodeURI(id),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            order: IOrder;
         }>;

         return new Order(payload.data.order);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async cancelOrderById(id: string, note?: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               httpCode: 401,
               message: "401 Unauthorized error",
               errors: {
                  process: [
                     {
                        code: "401",
                        message: "401 Unauthorized error",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/order`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               id: encodeURI(id),
            },
            body: JSON.stringify({
               note,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            cancelledOrder: IOrder;
         }>;

         return new Order(payload.data.cancelledOrder);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async checkEmailUpdate(email: string) {
      try {
         const response = await Model.fetchData({
            method: "POST",
            isRequiredAuthorization: true,
            endPoint: "user/email/check-for-update",
            body: {
               email: email,
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            valid: boolean;
            ["errors"]: {
               [key: string]: {
                  ["code"]: string | number;
                  ["message"]: string;
               }[];
            };
         }>;
         if (!payload.data.valid) {
            return new ModelError({
               httpCode: 500,
               message: "",
               ["errors"]: payload.data.errors,
            });
         }
         return payload.data.valid;
      } catch (error) {
         return error;
      }
   }
   public static async checkPhoneUpdate(phone: string) {
      try {
         const response = await Model.fetchData({
            method: "POST",
            isRequiredAuthorization: true,
            endPoint: "user/phone/check-for-update",
            body: {
               phone: phone,
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            valid: boolean;
            ["errors"]: {
               [key: string]: {
                  ["code"]: string | number;
                  ["message"]: string;
               }[];
            };
         }>;
         if (!payload.data.valid) {
            return new ModelError({
               httpCode: 500,
               message: "",
               ["errors"]: payload.data.errors,
            });
         }
         return payload.data.valid;
      } catch (error) {
         return error;
      }
   }
   public static async updateEmailOTPRequest(
      email: string
      // password: string
   ) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/email`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               email: email,
               // "password": password
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            _id: string;
         }>;

         return payload.data;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async updateEmailOTP(OTP_id: string, OTP_code: number) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/email`, {
            method: "PATCH",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               "OTP.id": OTP_id,
               "OTP.code": OTP_code,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         // const payload = await response.json() as TPayload<{
         //     "_id": string
         // }>;

         return;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async updatePhoneOTPRequest(phone: string, captchat: string) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/phone/v2`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ["captchat"]: captchat,
            },
            body: JSON.stringify({
               phone: phone,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            _id: string;
         }>;

         return payload.data;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async updatePhoneOTP(OTP_id: string, OTP_code: number) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/phone`, {
            method: "PATCH",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               "OTP.id": OTP_id,
               "OTP.code": OTP_code,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         // const payload = await response.json() as TPayload<{
         //     "_id": string
         // }>;

         return;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async resetPasswordGetMethod(account: string) {
      try {
         const response = await fetch(`${apiDomain}/www/user/reset-password/v2`, {
            method: "GET",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               account: encodeURI(account),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            hiddenEmail: string;
            hiddenPhone: string;
         }>;

         return payload.data;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async resetPasswordOTPRequest(account: string, method: EMethod.email | EMethod.sms = EMethod.email, captchat: string) {
      try {
         const response = await fetch(`${apiDomain}/www/user/reset-password/v2`, {
            method: "POST",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               captchat: captchat || "",
            },
            body: JSON.stringify({
               account: account,
               method: method,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            _id: string;
         }>;

         return payload.data;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async resetPasswordOTP(password: string, id: string, code: number, account?: string, method?: string) {
      try {
         const response = await fetch(`${apiDomain}/www/user/reset-password/v2`, {
            method: "PATCH",
            // "cache": "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               password: password,
               "OTP.id": id,
               "OTP.code": code,
               account: account,
               method: method,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         return;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async verifyOTP(account: string, otpId: string, authCode: number, method: EMethod, otpType: EOTPType) {
      const response = await fetch(`${apiDomain}/www/user/otp/verify`, {
         method: "POST",
         headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            otpId: otpId,
            account: account ?? null,
            authCode: authCode,
            authMethod: method,
            otpType: otpType,
         }),
      });
      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }
      const payload = (await response.json()) as TPayload<{
         account: string;
         authCode: string;
      }>;
      return payload;
   }
   public static async commentCommunity(content: string, communityPostId: string, communityPostCommentId: string | null, replyToUserIds: string[] = []) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/community/post/comment`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               content: content,
               communityPostId: communityPostId,
               communityPostCommentId: communityPostCommentId ?? null,
               replyToUserIds: replyToUserIds,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newComment: ICommunityComment;
         }>;

         return new CommunityComment(payload.data.newComment);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   //community comment
   public static async deleteCommentCommunity(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/community/post/comment`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         // const payload = await response.json() as TPayload<{
         //     "newComment": ICommunityComment
         // }>;

         return;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async reactionCommunity(type: "like", communityPostId: string, communityPostCommentId: string | null) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/community/reaction`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               type: type,
               communityPostId: communityPostId,
               communityPostCommentId: communityPostCommentId ?? null,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newCommunityReaction: ICommunityReaction;
         }>;

         return new CommunityReaction(payload.data.newCommunityReaction);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async deleteReactionCommunity(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/community/reaction`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         // const payload = await response.json() as TPayload<{
         //     "newComment": ICommunityComment
         // }>;

         return;
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   // actualWork comment
   public static async commentActualWork(content: string, actualWorkId: string, actualWorkCommentId: string | null, replyToUserIds: string[] = []) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/actualWork/comment`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               content: content,
               actualWorkId: actualWorkId,
               actualWorkCommentId: actualWorkCommentId ?? null,
               replyToUserIds: replyToUserIds,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newComment: IActualWorkComment;
         }>;

         return new ActualWorkComment(payload.data.newComment);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async deleteCommentActualWork(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/actualWork/comment`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async reactionActualWork(type: "like", actualWorkId: string, actualWorkCommentId: string | null) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/actualWork/reaction`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               type: type,
               actualWorkId: actualWorkId,
               actualWorkCommentId: actualWorkCommentId ?? null,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newActualWorkReaction: IActualWorkReaction;
         }>;

         return new ActualWorkReaction(payload.data.newActualWorkReaction);
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async deleteReactionActualWork(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/actualWork/reaction`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         return;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   // article comment
   public static async commentArticle(content: string, articleId: string, articleCommentId: string | null, replyToUserIds: string[] = []) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/article/comment`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               content: content,
               articleId: articleId,
               articleCommentId: articleCommentId ?? null,
               replyToUserIds: replyToUserIds,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newComment: IArticleComment;
         }>;

         return new ArticleComment(payload.data.newComment);
      } catch (error) {
         console.error(error);
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async deleteCommentArticle(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/Article/comment`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async reactionArticle(type: "like", articleId: string, articleCommentId: string | null) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/Article/reaction`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               type: type,
               articleId: articleId,
               articleCommentId: articleCommentId ?? null,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newArticleReaction: IArticleReaction;
         }>;

         return new ArticleReaction(payload.data.newArticleReaction);
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async deleteReactionArticle(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/user/Article/reaction`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         return;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    *
    * @param userId
    */
   public static async getProfile() {
      const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
      const profile = this.getUserById(undefined, token as any);
      return profile;
   }
   public static async getUserById(userId?: string, cookiesToken?: string) {
      const token = typeof window !== "undefined" && Cookies.get(tokenKey);
      const lastToken = cookiesToken || token;

      if (!lastToken && !userId) return;

      const response = await Model.fetchData({
         method: "GET",
         endPoint: "profile",
         headers: {
            ...(userId ? { id: userId } : undefined),
            ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
         },
      });

      if (response instanceof ModelError) return response;
      const payload = (await (response as unknown as Response).json()) as TPayload<{
         profile: IUser;
      }>;
      return new User(payload.data.profile);
   }

   /**
    * Get the list bookmark of user
    * @param userId
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async getUserSocialFeedPaginate(
      userId: string,
      page: number,
      limit: number = 10,
      sort: "asc" | "desc" = "desc",
      sortBy: "createdAt" = "createdAt",
      offset?: number,
      type?: string
   ) {
      const response = await Model.fetchGet(
         null,
         {
            ...(!page ? undefined : { page: String(page) }),
            limit: String(limit),
            ...(!offset ? undefined : { offset }),
            sort: sort,
            type: type === "hoi-dap" ? "qna" : "socialFeed",
            "sort-by": sortBy,
            "profile-id": userId,
         },
         "profile/social-feed/paginate"
      );
      if (response instanceof ModelError) return response;
      const payload = (await (response as unknown as Response).json()) as TPayload<{
         paginator: TPaginate<ISocialFeed>;
      }>;
      const paginate = new Paginate(payload.data.paginator, SocialFeed);

      return paginate;
   }

   /**
    * Get the list bookmark of user
    * @param userId
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async getUserCommunityPostPaginate(
      userId: string,
      page: number,
      limit: number = 10,
      sort: "asc" | "desc" = "desc",
      sortBy: "createdAt" = "createdAt",
      offset?: number
   ) {
      const response = await Model.fetchGet(
         null,
         {
            ...(!page ? undefined : { page: String(page) }),
            ...(!offset ? undefined : { offset: String(offset) }),
            limit: String(limit),
            sort: sort,
            "sort-by": sortBy,
            "profile-id": userId,
         },
         "profile/my-sweet-home/paginate"
      );
      if (response instanceof ModelError) return response;
      const payload = (await (response as unknown as Response).json()) as TPayload<{
         paginator: TPaginate<ICommunity>;
      }>;
      const paginate = new Paginate(payload.data.paginator, Community);

      return paginate;
   }

   /**
    * Get the list suggestion of user
    * @param page
    * @param limit
    * @param offset
    * @param sort
    * @param sortBy
    * @returns
    */
   // public static async getSuggestions({
   //    page,
   //    limit = 5,
   //    offset,
   //    sort,
   //    sortBy
   // }: {
   //    page?: number;
   //    limit?: number;
   //    offset?: number;
   //    sort?: "asc" | "desc";
   //    sortBy?: "createdAt";
   // }) {
   //    const response = await Model.fetchData({
   //       method: "GET",
   //       endPoint: "user/subscribe/suggest/paginate",
   //       headers: {
   //          ...(!page ? undefined : { page: String(page) }),
   //          ...(!limit ? undefined : { limit: String(limit) }),
   //          ...(!offset ? undefined : { offset: String(offset) }),
   //          ...(!sort ? undefined : { sort }),
   //          ...(!sortBy ? undefined : { sortBy })
   //       }
   //       // isRequiredAuthorization: true
   //    });
   //    if (response instanceof ModelError) return response;
   //    const payload = (await response.json()) as TPayload<{
   //       paginator: TPaginate<IUser>;
   //    }>;
   //    const paginate = new Paginate(payload.data.paginator, User);

   //    return paginate;
   // }
   public static async getSuggestionsShuffle() {
      const response = await Model.fetchData({
         method: "GET",
         endPoint: "user/subscribe/suggest/shuffle",
      });
      if (response instanceof ModelError) return response;

      const payload = (await response.json()) as TPayload<{
         suggestedUser: User[];
      }>;
      return payload.data.suggestedUser.map((i: User) => new User(i));
   }

   public static async paginate({
      page,
      limit = 5,
      offset,
      sort,
      sortBy,
      keywords,
      token,
   }: {
      page?: number;
      limit?: number;
      offset?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt";
      keywords?: string;
      token?: string;
   }) {
      const cookiesToken = token ? token : typeof window !== "undefined" ? localStorage.getItem(tokenKey) : undefined;
      const res = await fetch(`${apiDomain}/www/user/paginate`, {
         method: "GET",
         cache: "no-store",
         headers: {
            ["Origin"]: "https://spacet.vn",
            "X-Requested-With": "XMLHttpRequest",
            ...(!cookiesToken ? undefined : { Authorization: `Bearer ${cookiesToken}` }),
            ...(!page ? undefined : { page: String(page) }),
            limit: !limit ? String(20) : String(limit),
            offset: !offset ? String(0) : String(offset),
            sort: !sort ? "desc" : encodeURI(sort),
            "sort-by": !sortBy ? "createdAt" : encodeURI(sortBy),
            ...(!keywords ? undefined : { keywords: encodeURIComponent(keywords) }),
         },
      });

      if (!res.ok) {
         return new ModelError((await res.json()) as TModelError);
      }

      if (res instanceof ModelError) return new ModelError(res);

      const payload = (await res.json()) as TPayload<{
         paginator: TPaginate<IUser>;
      }>;

      const paginate = new Paginate(payload.data.paginator, User);
      return paginate;
   }

   public static async switchBadge() {
      const res = await Model.fetchData({
         method: "POST",
         endPoint: "profile/settings/badge",
      });
      if (res instanceof ModelError) {
         return res;
      }
      const payload = (await res.json()) as TPayload<boolean>;
      return payload;
   }

   public static async notifySetting(settings: {
      browser: boolean;
      like: boolean;
      comment: boolean;
      newFollow: boolean;
      newPost: boolean;
      order: boolean;
      coin: boolean;
   }) {
      const res = await Model.fetchData({
         method: "POST",
         endPoint: "profile/settings",
         body: {
            settings: {
               notifications: {
                  feed: {
                     like: settings.like,
                     comment: settings.comment,
                     newFollow: settings.newFollow,
                     newPost: settings.newPost,
                  },
                  order: settings.order,
                  coin: settings.coin,
               },
            },
         },
      });

      if (res instanceof ModelError) {
         return res;
      }

      const payload = (await res.json()) as TPayload<any>;
      return payload;
   }
   public static async notifySettingBrowser(settings: { chrome: boolean }) {
      const res = await Model.fetchData({
         method: "POST",
         endPoint: "profile/settings/browser",
         body: {
            browser: {
               chrome: settings.chrome,
            },
         },
      });

      if (res instanceof ModelError) {
         return res;
      }

      const payload = (await res.json()) as TPayload<any>;
      return payload;
   }
}

export default User;

export const submitBulkOrder = async (data: {
   hotenkhachhang: string;
   dienthoaikhachhang: string;
   emailkhachhang?: string;
   sanpham: string;
   soluong: number;
}) => {
   const res = await Model.fetchData({
      method: "POST",
      endPoint: "consulting/bulk-order",
      body: data,
   });

   if (res instanceof ModelError) {
      return res;
   }

   const payload = (await res.json()) as TPayload<any>;
   return payload;
};

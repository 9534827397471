import UserCartByBrand, { IUserCartSummary } from "@Models/userCardByBrand";
import * as types from "./types";
import { ModelError } from "@Models/model";
import { IVoucherV2 } from "@Models/VoucherV2";

export interface ICartReducer {
   loading: boolean;
   data?: {
      ["paginate"]: UserCartByBrand[];
      ["shipping"]: {
         recommendVoucher?: IVoucherV2;
         appliedVoucher?: IVoucherV2;
         isNoAutoShipping?: boolean;
      };
      ["discount"]: {
         // isCheck: boolean;
         recommendVoucher?: IVoucherV2;
         appliedVoucher?: IVoucherV2;
         isNoAutoDiscount?: boolean;
      };
      ["summary"]: IUserCartSummary;
      ["maxCoinUsedPercent"]: number;
   };
   error?: ModelError | null;
}

const initState: ICartReducer = {
   loading: true,
};

export default function cartReducer(state = initState, action): ICartReducer {
   const { type, payload } = action;
   switch (type) {
      case types.GET_USER_CART:
         return {
            data: payload,
            loading: false,
            error: null, // Reset error
         };
      case types.GET_USER_CART_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };

      default:
         return state;
   }
}

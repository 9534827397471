import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import globalReducer from "./global/reducer";
import abTestReducer from "./reducer/abTest";
import authReducer from "./reducer/auth";
import bookmarkMediasBySpaceReducer from "./reducer/bookmarkMediasBySpace";
import categories from "./reducer/categories";
import socialFeedReducer from "./reducer/socialFeed";
import socialFeedHashtagReducer from "./socialFeed/reducer";
import utmParamsReducer from "./reducer/utmParams";
import searchReducer from "./search/reducer";
import cartReducer from "./cart/reducer";
import homeReducer from "./home/reducer";

const reducer = combineReducers({
   global: globalReducer,
   auth: authReducer,
   categories: categories,
   imageBySpace: bookmarkMediasBySpaceReducer,
   utmParams: utmParamsReducer,
   socialFeed: socialFeedReducer,
   socialFeedV2: socialFeedHashtagReducer,
   abTest: abTestReducer,
   search: searchReducer,
   cart: cartReducer,
   home: homeReducer,
});
const middleWare = (store: any) => (next: any) => (action: any) => {
   if (typeof action === "function") {
      return action(store.dispatch, store.getState());
   } else {
      next(action);
   }
};
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// type for redux devtool
declare global {
   interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
   }
}
const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(middleWare)));
export default store;

import Link from "next/link";
import React, { HTMLAttributes } from "react";
import store from "state";
import { toggleModalAuth } from "state/action/auth";
import { EAuthType } from "@Components/modal/auth/const";

const LinkOrActionAuth = ({
   isLogin,
   href,
   onClick,
   authType = EAuthType.signup,
   style,
   children,
   ...props
}: {
   isLogin?: boolean;
   href?: string;
   onClick?: () => void;
   authType?: EAuthType;
   style?: HTMLAttributes<HTMLStyleElement>;
   children: any;
   [t: string]: any;
}) => {
   const TagName: any = !isLogin || !href ? "a" : href.startsWith("http") ? "a" : Link;
   const otherProps = isLogin
      ? {
           href,
           onClick,
        }
      : {
           onClick: () => {
              store.dispatch(toggleModalAuth({ visible: true, type: authType, cbs: onClick }));
           },
        };

   return (
      <TagName style={{ cursor: "pointer", ...(style || {}) }} {...otherProps} {...props}>
         {children}
      </TagName>
   );
};

export default LinkOrActionAuth;

import classNames from "classnames"
import { HTMLAttributes, PropsWithChildren } from "react"

interface IProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
   className?: string
   disabled?: boolean
   type?: "primary" | "secondary"
}

function VoucherLabel({
   className,
   type = "primary",
   disabled,
   children,
   ...props
}: IProps) {
   return (
      <div className={classNames("voucher-label", className, disabled ? "disabled" : "")} {...props}>
         <div className={classNames("label-text", type)}>
            {children}
         </div>
         {/* Left */}
         <span className="dot-ef" ></span>
         <span className="dot-ef" ></span>
         <span className="dot-ef" ></span>
         {/* right */}
         <span className="dot-ef" ></span>
         <span className="dot-ef" ></span>
         <span className="dot-ef" ></span>
      </div>
   )
}

export default VoucherLabel
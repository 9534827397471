import * as types from "./types";

export const setGlobalLoading = (status?: boolean) => {
   return {
      type: types.SET_GLOBAL_LOADING,
      payload: Boolean(status)
   };
};
export const toggleModalError = (payload?: { visible?: boolean; type?: "success" | "error" | "warning"; title?: any; description?: any }) => {
   return {
      type: types.TOGGLE_MODAL_ERROR,
      payload
   };
};
export const toggleModalDownloadDesktop = (payload?: { visible?: boolean; data?: any }) => {
   return {
      type: types.TOGGLE_MODAL_DOWNLOAD_DESKTOP,
      payload
   };
};
export const toggleModalDownloadMobile = (payload?: { visible?: boolean; data?: any }) => {
   return {
      type: types.TOGGLE_MODAL_DOWNLOAD_MOBILE,
      payload
   };
};
export const captchaConfigSuccess = (payload: boolean) => {
   return {
      type: 'CAPTCHA_CONFIG_SUCCESS',
      payload
   }
}

import { formatAmount } from "helpers";
import Model, { ModelError, TPayload } from "./model";

export enum EVoucher {
   SHOP_VOUCHER = "SHOP_VOUCHER",
   PLATFORM_VOUCHER = "PLATFORM_VOUCHER",
}
export enum EVoucherType {
   SHIPPING_VOUCHER = "SHIPPING_VOUCHER",
   DISCOUNT_VOUCHER = "DISCOUNT_VOUCHER",
   GIFT_VOUCHER = "GIFT_VOUCHER",
   COIN_VOUCHER = "COIN_VOUCHER",
}
export enum EVoucherMethod {
   COUPON_VOUCHER = "COUPON_VOUCHER",
   CODE_VOUCHER = "CODE_VOUCHER",
}
export type TVoucherUsed = {
   shopVouchers?: {
      brandId: string;
      discountVoucherId: string;
      isNoAuto?: boolean;
   }[];
   platformVoucher?: {
      shippingVoucherId?: string;
      discountVoucherId?: string;
      isNoAutoDiscount?: boolean;
      isNoAutoShipping?: boolean;
   };
};

export interface IVoucherV2 extends Model {
   canRecommend: boolean;
   canUse: boolean;
   inUse: boolean;
   code: string;
   conditions: { minValue: number; expiredDate: string };
   description: string;
   discount: {
      label?: string;
      type: "AMOUNT" | "PERCENT";
      percentOff: number;
      amountOff: number;
   };
   maxEffectValue: number;
   additionalValue: number;
   method: EVoucherMethod;
   type: EVoucherType;
   "__info": {
      "title": string;
      "requirement": string;
      "expiration": string;
      "icon": string;
      "shortTitle": string;
      "expireColor": string,
   }
}

export default class VoucherV2 extends Model<IVoucherV2> implements IVoucherV2 {
   canRecommend: boolean;
   canUse: boolean;
   inUse: boolean;
   code: string;
   conditions: { minValue: number; expiredDate: string };
   description: string;
   discount: {
      label?: string;
      type: "AMOUNT" | "PERCENT";
      percentOff: number;
      amountOff: number;
   };
   maxEffectValue: number;
   additionalValue: number;
   method: EVoucherMethod;
   type: EVoucherType;
   "__info": {
      "title": string;
      "requirement": string;
      "expiration": string;
      "icon": string;
      "shortTitle": string;
      "expireColor": string,
   }

   constructor(data: IVoucherV2) {
      super(data);
      Object.assign(this, data);

      if (this.discount) {
         this.discount.label = this.discount.type === "AMOUNT" ? formatAmount(+this.discount?.amountOff) : this.discount.percentOff?.toString() + "%";
      }
   }

   static async getVouchers({
      type = EVoucher.PLATFORM_VOUCHER,
      brandId,
      cartIds,
      ids,
      codes,
      code,
      voucherUsed
   }: {
      type: EVoucher;
      brandId?: string;
      cartIds?: string[];
      ids?: string[];
      codes?: string[];
      code?: string;
      voucherUsed?: TVoucherUsed;
   }) {
      const res = await Model.fetchData({
         method: "POST",
         endPoint: type === EVoucher.PLATFORM_VOUCHER ? "voucher/platform" : "voucher/shop",
         headers: {
            ...(brandId ? { ["id"]: brandId } : undefined),
            ...(ids?.toString() ? { ["ids"]: ids?.toString() } : undefined),
            ...(cartIds?.toString() ? { ["cart-ids"]: cartIds?.toString() } : undefined),
            ...(codes?.toString() ? { ["codes"]: codes?.toString() } : undefined),
            ...(code ? { ["code"]: code } : undefined),
         },
         body: {
            voucherUsed
         }
      });
      if (res instanceof ModelError) return res;
      const payload = (await res.json()) as TPayload<{
         vouchers: {
            type: EVoucherType;
            vouchers: VoucherV2[];
         }[];
      }>;
      return {
         vouchers: payload.data.vouchers
            ? payload.data.vouchers.map((it) => {
               return {
                  ...it,
                  vouchers: it.vouchers.map((sub) => new VoucherV2(sub)),
               };
            })
            : undefined,
      };
   }
   static async getShopVoucher({ cardIds, ids, codes, code }: { cardIds?: string[]; ids?: string[]; codes?: string[]; code?: string }) {
      const res = await Model.fetchData({
         method: "GET",
         endPoint: "voucher/shop",
         headers: {
            ...(cardIds?.toString() ? { ["cart-ids"]: cardIds?.toString() } : undefined),
            ...(ids?.toString() ? { ["ids"]: ids?.toString() } : undefined),
            ...(codes?.toString() ? { ["codes"]: codes?.toString() } : undefined),
            ...(code ? { ["code"]: code } : undefined),
         },
      });
      if (res instanceof ModelError) return res;
      const payload = (await res.json()) as TPayload<{
         vouchers: {
            type: EVoucherType;
            vouchers: VoucherV2[];
         }[];
      }>;
      return {
         vouchers: payload.data.vouchers ? payload.data.vouchers.map((it) => {
            return {
               ...it,
               vouchers: it.vouchers.map((sub) => new VoucherV2(sub)),
            };
         })
            : undefined,
      };
   }
   static async getShopVoucherRecommned({ brandId, cartIds }: { brandId: string; cartIds?: string[] }) {
      const res = await this.fetchData({
         method: "POST",
         endPoint: "voucher/shop/recommend",
         headers: {
            ["id"]: brandId,
            ...(cartIds?.toString() ? { ["cart-ids"]: cartIds.toString() } : undefined),
         },
      });
      if (res instanceof ModelError) {
         return res;
      }
      const payload = (await res.json()) as TPayload<{ discount: { voucher: VoucherV2; recommendVoucher: VoucherV2 } }>;
      return {
         discount: {
            ...payload.data.discount,
            voucher: payload.data.discount.voucher ? new VoucherV2(payload.data.discount.voucher) : undefined,
            recommendVoucher: payload.data.discount.recommendVoucher ? new VoucherV2(payload.data.discount.recommendVoucher) : undefined,
         },
      };
   }
   static async getPlatformVoucherRecommned({ cartIds, voucherUsed }: {
      cartIds?: string[],
      voucherUsed?: { brandId: string, discountVoucherId: string }[];
   }) {
      const res = await this.fetchData({
         method: "POST",
         endPoint: "voucher/platform/recommend",
         headers: {
            ...(cartIds?.toString ? { ["cart-ids"]: cartIds.toString() } : undefined),
         },
         body: {
            voucherUsed
         }
      });
      if (res instanceof ModelError) {
         return res;
      }
      const payload = (await res.json()) as TPayload<{
         discount: { voucher: VoucherV2; recommendVoucher: VoucherV2 };
         shipping: { voucher: VoucherV2; recommendVoucher: VoucherV2 };
      }>;
      return {
         discount: {
            ...payload.data.discount,
            voucher: payload.data.discount.voucher ? new VoucherV2(payload.data.discount.voucher) : undefined,
            recommendVoucher: payload.data.discount.recommendVoucher ? new VoucherV2(payload.data.discount.recommendVoucher) : undefined,
         },
         shipping: {
            ...payload.data.shipping,
            voucher: payload.data.shipping.voucher ? new VoucherV2(payload.data.shipping.voucher) : undefined,
            recommendVoucher: payload.data.shipping.recommendVoucher ? new VoucherV2(payload.data.shipping.recommendVoucher) : undefined,
         },
      };
   }
}

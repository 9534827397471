import { Dialog, Drawer } from "@mui/material";
import Slider from "react-slick";
import React, { ReactNode, useEffect, useState } from "react";
import CreatePostIcon from "./create-post-icon.png";
import ReviewProductIcon from "./review-product-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { openModalPost } from "state/action/socialFeed";
import { ESocialFeedType } from "models/socialFeed.model";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import User from "models/user.model";
import Order, { EOrderStatus, IOrder } from "models/order.model";
import { ModelError, Paginate } from "models/model";
import { RootState } from "state";
import { dotNum } from "utils";

interface IProps {
   visible?: boolean;
   onClose?: () => void;
}

const ModalReceiveCoin = ({ visible, onClose }: IProps) => {
   const disaptch = useDispatch();
   const router = useRouter();
   const { points } = useSelector((state: RootState) => state.auth);
   const [orderList, setOrderList] = useState<Paginate<Order, IOrder>>();
   const [sliderData] = useState([
      {
         imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/receive-coin-banner.webp",
         imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/receive-coin-banner.webp",
         title: "Tích luỹ xu mua sắm",
         url: "/",
      },
      // {
      //    imgPc: "/assets/img/auth/art-board-02.webp",
      //    imgMb: "/assets/img/auth/art-board-02.webp",
      //    title: "Trải nhiệm nhiều tính năng mới",
      //    url: "/quy-trinh-ket-noi?internal=home-banner-2-v2"
      // },
      // {
      //    imgPc: "/assets/img/auth/art-board-03.webp",
      //    imgMb: "/assets/img/auth/art-board-03.webp",
      //    title: "Mạng xã hội của cộng đồng yêu nhà",
      //    url: "/bao-gia?internal=home-banner-3-v2"
      // }
   ]);

   const settingsSlider = {
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
   };

   const handleClose = () => {
      onClose?.();
   };

   useEffect(() => {
      User.getOrderPaginate({
         ["limit"]: 1,
         ["page"]: 1,
         sort: "desc",
         sortBy: "createdAt",
         ["status"]: EOrderStatus.successfullyDelivered,
      }).then((res) => {
         if (res instanceof ModelError) return;
         setOrderList(res);
      });
   }, []);

   const isNotBuyProduct = !orderList || (orderList && orderList?.totalDocs <= 0);

   return (
      <Layout visible={visible} onClose={handleClose}>
         <div className="row gx-0">
            {!isMobile && (
               <div className="col-md-5_7">
                  <div className="modal-left">
                     <Slider {...settingsSlider}>
                        {sliderData.map((banner, i) => {
                           return (
                              <div className="slider-item" key={i}>
                                 {/* <Link title="Banner" href={banner.url ?? ""} className="cover" onClick={() => this.setPreviousURL(banner)}></Link> */}
                                 <picture>
                                    <source media="(min-width: 768px)" srcSet={banner.imgPc ?? ""} />
                                    <source media="(max-width: 767px)" srcSet={banner.imgMb ?? ""} />
                                    <img height={"100%"} width="100%" title={banner.title ?? ""} alt={banner.title ?? ""} src={banner.imgPc ?? ""} />
                                 </picture>
                              </div>
                           );
                        })}
                     </Slider>
                  </div>
               </div>
            )}
            <div className="col-12 col-md-6_3">
               <div className="modal-right">
                  <div className="current-coin">
                     <span className="name">Coin đang có</span>
                     <div className="coin-wrap">
                        <span className="value">{points?.useablePoint ? dotNum(points?.useablePoint) : 0}</span>
                        <svg className="coin-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <ellipse cx="13.1092" cy="11.9651" rx="9.83187" ry="10.553" fill="#BFA01D" />
                           <ellipse cx="11.5975" cy="11.9651" rx="9.83187" ry="10.553" fill="#FFEAAD" />
                           <ellipse cx="11.5979" cy="11.9658" rx="7.8655" ry="8.44238" fill="#FFD630" />
                           <path
                              d="M14.7436 14.1013C14.7436 14.559 14.6352 14.9893 14.4186 15.392C14.2105 15.7948 13.8985 16.1335 13.4825 16.4082C13.0751 16.6828 12.5898 16.8476 12.0264 16.9025V18.0147H11.1813V16.9025C10.3666 16.8201 9.70792 16.5501 9.20522 16.0923C8.70252 15.6346 8.45117 15.0304 8.45117 14.2798H10.0373C10.0633 14.6368 10.1716 14.9343 10.3623 15.1723C10.553 15.4012 10.826 15.5477 11.1813 15.6117V12.5908C10.6006 12.4352 10.1283 12.2796 9.76426 12.124C9.40023 11.9592 9.08821 11.7029 8.8282 11.355C8.57685 11.0071 8.45117 10.5311 8.45117 9.92694C8.45117 9.16714 8.70252 8.54007 9.20522 8.04574C9.70792 7.54226 10.3666 7.24932 11.1813 7.16693V6.05469H12.0264V7.16693C12.7891 7.24016 13.4002 7.49648 13.8595 7.93589C14.3275 8.36614 14.5919 8.96117 14.6526 9.72097H13.0665C13.0405 9.43719 12.9365 9.18545 12.7544 8.96574C12.5724 8.73689 12.3298 8.58126 12.0264 8.49888V11.4649C12.6071 11.6113 13.0795 11.7669 13.4435 11.9317C13.8075 12.0873 14.1152 12.3391 14.3666 12.687C14.6179 13.0257 14.7436 13.4971 14.7436 14.1013ZM9.95927 9.84455C9.95927 10.2199 10.0633 10.5128 10.2713 10.7234C10.488 10.9339 10.7913 11.1033 11.1813 11.2314V8.45768C10.8087 8.50345 10.5096 8.64534 10.2843 8.88336C10.0676 9.12137 9.95927 9.44177 9.95927 9.84455ZM12.0264 15.6117C12.4078 15.5477 12.7068 15.3875 12.9235 15.1311C13.1401 14.8657 13.2485 14.5498 13.2485 14.1837C13.2485 13.8175 13.1401 13.5337 12.9235 13.3323C12.7154 13.1218 12.4164 12.9524 12.0264 12.8243V15.6117Z"
                              fill="#FFFBDC"
                           />
                        </svg>
                     </div>
                  </div>
                  <div className="br-link">
                     <img src="/assets/img/icon/hr-bottom.png" alt="" />
                  </div>
                  <div className="list-mission">
                     <div className="mission-item">
                        <div className="mission-item__content">
                           <div className="mission-item__left">
                              <div className="b-icon">
                                 <img src={CreatePostIcon.src} alt="" />
                              </div>
                              <svg className="space-icon" xmlns="http://www.w3.org/2000/svg" width="2" height="24" viewBox="0 0 2 24" fill="none">
                                 <path d="M1 0V24" stroke="black" strokeOpacity="0.1" />
                              </svg>
                              <div>
                                 <span className="item-title">Tạo bài đăng</span>
                                 <div className="d-flex align-items-center">
                                    <span className="plus">+</span>
                                    <svg className="coin-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                       <ellipse cx="8.73952" cy="7.97673" rx="6.55458" ry="7.03532" fill="#BFA01D" />
                                       <ellipse cx="7.73171" cy="7.97673" rx="6.55458" ry="7.03532" fill="#FFEAAD" />
                                       <ellipse cx="7.73195" cy="7.97787" rx="5.24367" ry="5.62826" fill="#FFD630" />
                                       <path
                                          d="M9.82909 9.40151C9.82909 9.70665 9.75686 9.99348 9.61241 10.262C9.47373 10.5305 9.26572 10.7563 8.98837 10.9394C8.7168 11.1225 8.39322 11.2324 8.01764 11.269V12.0105H7.45427V11.269C6.91113 11.2141 6.47199 11.034 6.13685 10.7289C5.80172 10.4237 5.63416 10.0209 5.63416 9.52051H6.69156C6.70889 9.75852 6.78112 9.95687 6.90824 10.1155C7.03536 10.2681 7.21737 10.3658 7.45427 10.4085V8.39454C7.06714 8.29079 6.75223 8.18704 6.50954 8.08329C6.26686 7.97344 6.05885 7.80256 5.8855 7.57066C5.71794 7.33875 5.63416 7.0214 5.63416 6.61861C5.63416 6.11208 5.80172 5.69403 6.13685 5.36448C6.47199 5.02882 6.91113 4.83353 7.45427 4.77861V4.03711H8.01764V4.77861C8.52612 4.82743 8.93348 4.99831 9.23972 5.29124C9.55174 5.57808 9.72797 5.97476 9.76842 6.4813H8.71102C8.69368 6.29211 8.62435 6.12428 8.50301 5.97781C8.38166 5.82524 8.21988 5.72149 8.01764 5.66657V7.64389C8.40478 7.74154 8.71969 7.84528 8.96237 7.95513C9.20505 8.05888 9.41017 8.22671 9.57774 8.45862C9.74531 8.68442 9.82909 8.99872 9.82909 9.40151ZM6.63955 6.56369C6.63955 6.8139 6.70889 7.00919 6.84757 7.14956C6.99202 7.28992 7.19426 7.40283 7.45427 7.48827V5.63911C7.20581 5.66962 7.00647 5.76421 6.85623 5.92289C6.71178 6.08156 6.63955 6.29516 6.63955 6.56369ZM8.01764 10.4085C8.27188 10.3658 8.47123 10.259 8.61568 10.0881C8.76013 9.9111 8.83236 9.70055 8.83236 9.45643C8.83236 9.21232 8.76013 9.02313 8.61568 8.88887C8.477 8.7485 8.27766 8.6356 8.01764 8.55016V10.4085Z"
                                          fill="#FFFBDC"
                                       />
                                    </svg>
                                    <span className="coin-value">10.000</span>
                                 </div>
                              </div>
                           </div>
                           <div
                              className="mission-item__right"
                              onClick={async () => {
                                 handleClose();
                                 if (!router.asPath.includes("/feed")) {
                                    await router.push("/feed");
                                 }
                                 disaptch(openModalPost({ visible: true, type: ESocialFeedType.socialFeed, showFirstHashtag: true }));
                              }}
                           >
                              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                                 <path
                                    d="M9.5 9C9.5 9.10574 9.48022 9.20487 9.44066 9.2974C9.4011 9.38992 9.33846 9.47584 9.25275 9.55514L1.81538 16.772C1.67033 16.924 1.48901 17 1.27143 17C1.12637 17 0.997802 16.967 0.885715 16.9009C0.767033 16.8348 0.674726 16.7423 0.608791 16.6233C0.536263 16.5109 0.5 16.3788 0.5 16.2268C0.5 16.0219 0.572527 15.8401 0.717583 15.6815L7.62088 9L0.717583 2.30855C0.572527 2.15655 0.5 1.9748 0.5 1.76332C0.5 1.61793 0.536263 1.48575 0.608791 1.36679C0.674726 1.24783 0.767033 1.15861 0.885715 1.09913C0.997802 1.03304 1.12637 1 1.27143 1C1.48901 1 1.67033 1.0727 1.81538 1.21809L9.25275 8.43494C9.33846 8.51425 9.4011 8.60017 9.44066 8.69269C9.48022 8.78521 9.5 8.88765 9.5 9Z"
                                    fill="white"
                                    stroke="white"
                                 />
                              </svg>
                           </div>
                        </div>
                     </div>
                     <div className="mission-item">
                        <div className="mission-item__content">
                           <div className="mission-item__left">
                              <div className="b-icon">
                                 <img src={ReviewProductIcon.src} alt="" />
                              </div>
                              <svg className="space-icon" xmlns="http://www.w3.org/2000/svg" width="2" height="24" viewBox="0 0 2 24" fill="none">
                                 <path d="M1 0V24" stroke="black" strokeOpacity="0.1" />
                              </svg>
                              <div>
                                 <span className="item-title">Đánh giá sản phẩm đã mua</span>
                                 <div className="d-flex align-items-center">
                                    <span className="plus">+</span>
                                    <svg className="coin-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                       <ellipse cx="8.73952" cy="7.97673" rx="6.55458" ry="7.03532" fill="#BFA01D" />
                                       <ellipse cx="7.73171" cy="7.97673" rx="6.55458" ry="7.03532" fill="#FFEAAD" />
                                       <ellipse cx="7.73195" cy="7.97787" rx="5.24367" ry="5.62826" fill="#FFD630" />
                                       <path
                                          d="M9.82909 9.40151C9.82909 9.70665 9.75686 9.99348 9.61241 10.262C9.47373 10.5305 9.26572 10.7563 8.98837 10.9394C8.7168 11.1225 8.39322 11.2324 8.01764 11.269V12.0105H7.45427V11.269C6.91113 11.2141 6.47199 11.034 6.13685 10.7289C5.80172 10.4237 5.63416 10.0209 5.63416 9.52051H6.69156C6.70889 9.75852 6.78112 9.95687 6.90824 10.1155C7.03536 10.2681 7.21737 10.3658 7.45427 10.4085V8.39454C7.06714 8.29079 6.75223 8.18704 6.50954 8.08329C6.26686 7.97344 6.05885 7.80256 5.8855 7.57066C5.71794 7.33875 5.63416 7.0214 5.63416 6.61861C5.63416 6.11208 5.80172 5.69403 6.13685 5.36448C6.47199 5.02882 6.91113 4.83353 7.45427 4.77861V4.03711H8.01764V4.77861C8.52612 4.82743 8.93348 4.99831 9.23972 5.29124C9.55174 5.57808 9.72797 5.97476 9.76842 6.4813H8.71102C8.69368 6.29211 8.62435 6.12428 8.50301 5.97781C8.38166 5.82524 8.21988 5.72149 8.01764 5.66657V7.64389C8.40478 7.74154 8.71969 7.84528 8.96237 7.95513C9.20505 8.05888 9.41017 8.22671 9.57774 8.45862C9.74531 8.68442 9.82909 8.99872 9.82909 9.40151ZM6.63955 6.56369C6.63955 6.8139 6.70889 7.00919 6.84757 7.14956C6.99202 7.28992 7.19426 7.40283 7.45427 7.48827V5.63911C7.20581 5.66962 7.00647 5.76421 6.85623 5.92289C6.71178 6.08156 6.63955 6.29516 6.63955 6.56369ZM8.01764 10.4085C8.27188 10.3658 8.47123 10.259 8.61568 10.0881C8.76013 9.9111 8.83236 9.70055 8.83236 9.45643C8.83236 9.21232 8.76013 9.02313 8.61568 8.88887C8.477 8.7485 8.27766 8.6356 8.01764 8.55016V10.4085Z"
                                          fill="#FFFBDC"
                                       />
                                    </svg>
                                    <span className="coin-value">1.000</span>
                                 </div>
                              </div>
                           </div>
                           <div
                              className={`mission-item__right ${isNotBuyProduct ? "disabled" : ""}`}
                              onClick={() => {
                                 if (isNotBuyProduct) return;
                                 handleClose();
                                 router.push("/tai-khoan/don-hang?type=product");
                              }}
                           >
                              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                                 <path
                                    d="M9.5 9C9.5 9.10574 9.48022 9.20487 9.44066 9.2974C9.4011 9.38992 9.33846 9.47584 9.25275 9.55514L1.81538 16.772C1.67033 16.924 1.48901 17 1.27143 17C1.12637 17 0.997802 16.967 0.885715 16.9009C0.767033 16.8348 0.674726 16.7423 0.608791 16.6233C0.536263 16.5109 0.5 16.3788 0.5 16.2268C0.5 16.0219 0.572527 15.8401 0.717583 15.6815L7.62088 9L0.717583 2.30855C0.572527 2.15655 0.5 1.9748 0.5 1.76332C0.5 1.61793 0.536263 1.48575 0.608791 1.36679C0.674726 1.24783 0.767033 1.15861 0.885715 1.09913C0.997802 1.03304 1.12637 1 1.27143 1C1.48901 1 1.67033 1.0727 1.81538 1.21809L9.25275 8.43494C9.33846 8.51425 9.4011 8.60017 9.44066 8.69269C9.48022 8.78521 9.5 8.88765 9.5 9Z"
                                    fill="white"
                                    stroke="white"
                                 />
                              </svg>
                           </div>
                        </div>
                        {isNotBuyProduct ? (
                           <div className={`mission-item__noproduct `}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.33337 1.33203H10.3867C12.5958 1.33203 14.3867 3.12289 14.3867 5.33203V10.3854C14.3867 12.5945 12.5958 14.3854 10.3867 14.3854H5.33337C3.12424 14.3854 1.33337 12.5945 1.33337 10.3854V5.33203C1.33337 3.12289 3.12424 1.33203 5.33337 1.33203ZM10.3867 13.3854C12.042 13.3817 13.383 12.0407 13.3867 10.3854V5.33203C13.383 3.6767 12.042 2.3357 10.3867 2.33203H5.33337C3.67804 2.3357 2.33704 3.6767 2.33337 5.33203V10.3854C2.33704 12.0407 3.67804 13.3817 5.33337 13.3854H10.3867Z"
                                    fill="#BFA01D"
                                 />
                                 <path
                                    d="M7.86004 5.3587C7.5854 5.36229 7.36363 5.58406 7.36004 5.8587C7.36002 6.13594 7.58282 6.36172 7.86004 6.36537C7.99438 6.36723 8.12366 6.31423 8.21803 6.2186C8.3124 6.12297 8.36368 5.993 8.36004 5.8587C8.36004 5.58256 8.13618 5.3587 7.86004 5.3587Z"
                                    fill="#BFA01D"
                                 />
                                 <path
                                    d="M7.86004 7.2787C7.5839 7.2787 7.36004 7.50256 7.36004 7.7787V9.8587C7.36004 10.1348 7.5839 10.3587 7.86004 10.3587C8.13618 10.3587 8.36004 10.1348 8.36004 9.8587V7.79203C8.36555 7.6566 8.31509 7.52486 8.22051 7.42776C8.12594 7.33066 7.99557 7.27675 7.86004 7.2787Z"
                                    fill="#BFA01D"
                                 />
                              </svg>
                              Bạn chưa mua bất kỳ sản phẩm nào
                           </div>
                        ) : (
                           ""
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   );
};

function Layout({ visible, fullHeight, onClose, children, ...props }: { visible?: boolean; fullHeight?: boolean; onClose: () => void; children: ReactNode }) {
   if (typeof window !== "undefined" && window?.innerWidth > 768) {
      return (
         <Dialog open={Boolean(visible)} onClose={onClose} className={`modal-receive`} {...props}>
            {!isMobile && (
               <div className="dialog-header">
                  <div className={`img-close d-none d-md-flex`} style={{ cursor: "pointer" }} onClick={onClose}>
                     <img src="/assets/img/icon/close.svg" alt="Space T" />
                  </div>
               </div>
            )}
            {children}
         </Dialog>
      );
   }
   return (
      <Drawer className={`modal-receive ${fullHeight ? "full-height" : ""}`} anchor="bottom" open={Boolean(visible)} onClose={onClose} {...props}>
         {isMobile && (
            <div className="drawer-header">
               <span>Coin</span>
               <div className={`img-close`} style={{ cursor: "pointer" }} onClick={onClose}>
                  <img src="/assets/img/icon/close.svg" alt="Space T" />
               </div>
            </div>
         )}
         {children}
      </Drawer>
   );
}

export default ModalReceiveCoin;

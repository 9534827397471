import * as types from "./types";

export interface IGlobalState {
   loading?: boolean;
   modalError: {
      visible?: boolean;
      type?: "success" | "error" | "warning";
      title?: any;
      description?: any;
   };
   modalDownloadDesktop: {
      visible?: boolean;
      data?: any;
   };
   modalDownloadMobile: {
      visible?: boolean;
      data?: any;
   };
   captchaStatus: boolean
}

const initState: IGlobalState = {
   loading: false,
   modalError: {
      visible: false
      // title: "Không khả dụng",
      // description: "Công cụ thiết kế 2D/3D hiện chỉ sử dụng <br/> trên máy tính"
   },
   modalDownloadDesktop: {
      visible: false
   },
   modalDownloadMobile: {
      visible: false
   },
   captchaStatus: false
};

export default function globalReducer(state = initState, action): IGlobalState {
   const { type, payload } = action;
   switch (type) {
      case types.SET_GLOBAL_LOADING:
         return {
            ...state,
            loading: !!payload
         };
      case types.TOGGLE_MODAL_ERROR:
         return {
            ...state,
            modalError: {
               ...state.modalError,
               visible: payload.visible,
               ...payload
            }
         };
      case types.TOGGLE_MODAL_DOWNLOAD_DESKTOP:
         return {
            ...state,
            modalDownloadDesktop: {
               visible: payload.visible,
               data: payload.data
            }
         };
      case types.TOGGLE_MODAL_DOWNLOAD_MOBILE:
         return {
            ...state,
            modalDownloadMobile: {
               visible: payload.visible,
               data: payload.data
            }
         };
      case 'CAPTCHA_CONFIG_SUCCESS':
         return {
            ...state,
            captchaStatus: payload
         };

      default:
         return state;
   }
}

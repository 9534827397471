import { EAuthType } from "@Components/modal/auth/const";
import { tokenKey } from "@Models/__variables";
import { ModelError } from "@Models/model";
import UserBookmark, { EUserBookmarkTypes } from "@Models/userBookmark.model";
import store from "state";
import { toggleModalAuth } from "state/action/auth";
import { debounce } from "utils";

export function useBookmark() {
   const handleBookmark = async (type: EUserBookmarkTypes, id: string, bookmarkId?: string) => {
      if (bookmarkId) {
         const unBookmark = await UserBookmark.unBookmark(bookmarkId);
         return unBookmark;
      } else {
         const activeBookmark = await UserBookmark.createUserBookmark(id, type);
         return activeBookmark;
      }
   };
   const checkAuthenticated = (func: (type: EUserBookmarkTypes, id: string, bookmarkId?: string) => Promise<UserBookmark | ModelError>, type: EUserBookmarkTypes) => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
         return func.bind(arguments, type);
      } else {
         store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signup }));
         return () => Promise.resolve(null);
      }
   };

   return {
      toggleBookmarkProduct: checkAuthenticated(debounce<UserBookmark | ModelError>(handleBookmark, 400), EUserBookmarkTypes.product),
      toggleBookmarkBlog: checkAuthenticated(debounce(handleBookmark, 400), EUserBookmarkTypes.blog),
   };
}

import { Dialog, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state";
import { toggleModalError } from "state/global/actions";
import styles from "./ModelError.module.scss";
import classNames from "classnames";

interface IProps {
   onSuccess?: () => void;
}

export default function ModalError({ onSuccess }: IProps) {
   const dispatch = useDispatch();
   const { visible, title, type, description } = useSelector<RootState>((state) => state.global.modalError) as {
      visible: boolean;
      title?: string;
      type?: "error" | "warning" | "info" | "success";
      description?: string;
   };

   const handleConfirm = () => {
      dispatch(toggleModalError({ visible: false }));
      onSuccess?.();
   };

   return (
      <>
         {/* Desktop modal */}
         <Dialog open={Boolean(visible)} className={styles["error-dialog"]} onClose={() => dispatch(toggleModalError({ visible: false }))}>
            <div className={styles["dialog-content"]}>
               {type && (
                  <>
                     {type === "success" ? (
                        <div className={styles["type-icon"]}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                              <path
                                 fill-rule="evenodd"
                                 clip-rule="evenodd"
                                 d="M30 60C33.9397 60 37.8407 59.224 41.4805 57.7164C45.1203 56.2087 48.4274 53.999 51.2132 51.2132C53.999 48.4274 56.2087 45.1203 57.7164 41.4805C59.224 37.8407 60 33.9397 60 30C60 26.0603 59.224 22.1593 57.7164 18.5195C56.2087 14.8797 53.999 11.5726 51.2132 8.7868C48.4274 6.00104 45.1203 3.79125 41.4805 2.28361C37.8407 0.775973 33.9397 -5.87054e-08 30 0C22.0435 1.18561e-07 14.4129 3.1607 8.7868 8.7868C3.16071 14.4129 0 22.0435 0 30C0 37.9565 3.16071 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60ZM29.2267 42.1333L45.8933 22.1333L40.7733 17.8667L26.44 35.0633L19.0233 27.6433L14.31 32.3567L24.31 42.3567L26.89 44.9367L29.2267 42.1333Z"
                                 fill="#52C41A"
                              />
                           </svg>
                        </div>
                     ) : type === "warning" ? (
                        <div className={styles["type-icon"]}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                              <path
                                 d="M40 7.5C33.5721 7.5 27.2886 9.40609 21.944 12.9772C16.5994 16.5484 12.4338 21.6242 9.97393 27.5628C7.51408 33.5014 6.87047 40.0361 8.12449 46.3404C9.37851 52.6448 12.4738 58.4358 17.019 62.981C21.5643 67.5262 27.3552 70.6215 33.6596 71.8755C39.964 73.1295 46.4986 72.4859 52.4372 70.0261C58.3758 67.5662 63.4516 63.4006 67.0228 58.056C70.5939 52.7114 72.5 46.4279 72.5 40C72.4909 31.3833 69.0639 23.122 62.9709 17.0291C56.878 10.9361 48.6168 7.5091 40 7.5ZM51.7688 48.2312C52.001 48.4635 52.1853 48.7393 52.311 49.0428C52.4367 49.3462 52.5014 49.6715 52.5014 50C52.5014 50.3285 52.4367 50.6538 52.311 50.9572C52.1853 51.2607 52.001 51.5365 51.7688 51.7688C51.5365 52.001 51.2607 52.1853 50.9573 52.311C50.6538 52.4367 50.3285 52.5014 50 52.5014C49.6715 52.5014 49.3463 52.4367 49.0428 52.311C48.7393 52.1853 48.4635 52.001 48.2313 51.7688L40 43.5344L31.7688 51.7688C31.5365 52.001 31.2607 52.1853 30.9573 52.311C30.6538 52.4367 30.3285 52.5014 30 52.5014C29.6715 52.5014 29.3463 52.4367 29.0428 52.311C28.7393 52.1853 28.4635 52.001 28.2313 51.7688C27.999 51.5365 27.8147 51.2607 27.689 50.9572C27.5633 50.6538 27.4986 50.3285 27.4986 50C27.4986 49.6715 27.5633 49.3462 27.689 49.0428C27.8147 48.7393 27.999 48.4635 28.2313 48.2312L36.4656 40L28.2313 31.7688C27.7622 31.2997 27.4986 30.6634 27.4986 30C27.4986 29.3366 27.7622 28.7003 28.2313 28.2312C28.7004 27.7621 29.3366 27.4986 30 27.4986C30.6634 27.4986 31.2997 27.7621 31.7688 28.2312L40 36.4656L48.2313 28.2312C48.4635 27.999 48.7393 27.8147 49.0428 27.689C49.3463 27.5633 49.6715 27.4986 50 27.4986C50.3285 27.4986 50.6538 27.5633 50.9573 27.689C51.2607 27.8147 51.5365 27.999 51.7688 28.2312C52.001 28.4635 52.1853 28.7393 52.311 29.0428C52.4367 29.3462 52.5014 29.6715 52.5014 30C52.5014 30.3285 52.4367 30.6538 52.311 30.9572C52.1853 31.2607 52.001 31.5365 51.7688 31.7688L43.5344 40L51.7688 48.2312Z"
                                 fill="#F5222D"
                              />
                           </svg>
                        </div>
                     ) : type === "error" ? (
                        <div className={styles["type-icon"]}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                              <path
                                 d="M40 7.5C33.5721 7.5 27.2886 9.40609 21.944 12.9772C16.5994 16.5484 12.4338 21.6242 9.97393 27.5628C7.51408 33.5014 6.87047 40.0361 8.12449 46.3404C9.37851 52.6448 12.4738 58.4358 17.019 62.981C21.5643 67.5262 27.3552 70.6215 33.6596 71.8755C39.964 73.1295 46.4986 72.4859 52.4372 70.0261C58.3758 67.5662 63.4516 63.4006 67.0228 58.056C70.5939 52.7114 72.5 46.4279 72.5 40C72.4909 31.3833 69.0639 23.122 62.9709 17.0291C56.878 10.9361 48.6168 7.5091 40 7.5ZM51.7688 48.2312C52.001 48.4635 52.1853 48.7393 52.311 49.0428C52.4367 49.3462 52.5014 49.6715 52.5014 50C52.5014 50.3285 52.4367 50.6538 52.311 50.9572C52.1853 51.2607 52.001 51.5365 51.7688 51.7688C51.5365 52.001 51.2607 52.1853 50.9573 52.311C50.6538 52.4367 50.3285 52.5014 50 52.5014C49.6715 52.5014 49.3463 52.4367 49.0428 52.311C48.7393 52.1853 48.4635 52.001 48.2313 51.7688L40 43.5344L31.7688 51.7688C31.5365 52.001 31.2607 52.1853 30.9573 52.311C30.6538 52.4367 30.3285 52.5014 30 52.5014C29.6715 52.5014 29.3463 52.4367 29.0428 52.311C28.7393 52.1853 28.4635 52.001 28.2313 51.7688C27.999 51.5365 27.8147 51.2607 27.689 50.9572C27.5633 50.6538 27.4986 50.3285 27.4986 50C27.4986 49.6715 27.5633 49.3462 27.689 49.0428C27.8147 48.7393 27.999 48.4635 28.2313 48.2312L36.4656 40L28.2313 31.7688C27.7622 31.2997 27.4986 30.6634 27.4986 30C27.4986 29.3366 27.7622 28.7003 28.2313 28.2312C28.7004 27.7621 29.3366 27.4986 30 27.4986C30.6634 27.4986 31.2997 27.7621 31.7688 28.2312L40 36.4656L48.2313 28.2312C48.4635 27.999 48.7393 27.8147 49.0428 27.689C49.3463 27.5633 49.6715 27.4986 50 27.4986C50.3285 27.4986 50.6538 27.5633 50.9573 27.689C51.2607 27.8147 51.5365 27.999 51.7688 28.2312C52.001 28.4635 52.1853 28.7393 52.311 29.0428C52.4367 29.3462 52.5014 29.6715 52.5014 30C52.5014 30.3285 52.4367 30.6538 52.311 30.9572C52.1853 31.2607 52.001 31.5365 51.7688 31.7688L43.5344 40L51.7688 48.2312Z"
                                 fill="#F5222D"
                              />
                           </svg>
                        </div>
                     ) : (
                        ""
                     )}
                  </>
               )}
               {typeof title === "string" ? <div className={styles["error-title"]}>{title}</div> : <div className={styles["error-title"]}>Có lỗi xảy ra!</div>}
               {description && <p className={classNames(styles["error-mess"], "mb-2")}>{description}</p>}
               <div className={styles["actions"]}>
                  <button className={styles["btn"]} onClick={handleConfirm}>
                     Đồng ý
                  </button>
               </div>
            </div>
         </Dialog>

         {/* Mobile drawer */}
         {/* <Drawer className="download-drawer-mobile" anchor="bottom" open={Boolean(visible)} onClose={() => dispatch(toggleModalError({ visible: false }))}>
            <div className="drawer-header">
               <div className="drawer-title">Tận hưởng trải nghiệm đầy đủ trong ứng dụng</div>
               <div className="drawer-description">Khám phá điều mới mẻ mỗi ngày</div>
            </div>
            <div className="drawer-content">1</div>
         </Drawer> */}
      </>
   );
}

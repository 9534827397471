import UserCartByBrand from "@Models/userCardByBrand";
import * as types from "./types";
import { TVoucherUsed } from "@Models/VoucherV2";
import { ModelError } from "@Models/model";
import { Dispatch } from "react";

export const fetchCartListAndCalculate = (params?: { captchat?: string; cartIds?: string[]; isUsePoint?: boolean; voucherUsed?: TVoucherUsed }) => {
   return async (dispatch: Dispatch<any>) => {
      const res = await UserCartByBrand.getCartListCalculate(params);
      if (res instanceof ModelError) {
         return dispatch({
            type: types.GET_USER_CART_ERROR,
            payload: res,
         });
      }
      return dispatch({
         type: types.GET_USER_CART,
         payload: res,
      });
   };
};

import classNames from "classnames";
import styles from "./PopoverShopCart.module.scss";
import Link from "@Components/Link";
import { currency } from "utils";
import { Tooltip } from "@mui/material";
import { Skeleton } from "@mui/material";
import ProductVariant, { IProductVariantInfo } from "@Models/productVariant.model";
import UserCartByBrand from "@Models/userCardByBrand";
import UserCart from "@Models/userCart.model";
import React, { useState } from "react";

export default function ProductItem({
   cartItem,
   productItem,
   isEnable,
   isSelected,
   showTooltipDelete,
   onCheckOrderItem,
   onUpdateItemCart,
   handleToggleDialogDelete,
   onRemoveProductFromCart,
   toggleBookmarkProduct,
}) {
   const tooltipDeleteRef = React.createRef<any>();

   const [isBookmark, setIsBookmark] = useState(!!productItem.__product.__currentUserBookmark);

   function renderQuantityBox(isEnable: boolean, cartItem: UserCartByBrand, productItem: UserCart) {
      return (
         <div className={styles["quantity-box"]}>
            <button
               className={styles["minus-button"]}
               title="Giảm số lượng"
               disabled={!isEnable || !!(productItem?.info?.quantity && productItem?.info?.quantity < 2)}
               onClick={() => {
                  if (productItem?.info?.quantity && productItem?.info?.quantity < 2) {
                     return;
                  }
                  onUpdateItemCart(cartItem, productItem, -1);
               }}
            >
               -
            </button>
            <input type="number" value={productItem?.info?.quantity} className={styles["quantity-input"]} readOnly />
            <button
               className={styles["plus-button"]}
               title="Tăng số lượng"
               disabled={!isEnable}
               onClick={() => {
                  onUpdateItemCart(cartItem, productItem, 1);
               }}
            >
               +
            </button>
         </div>
      );
   }
   function caculatePriceItem(currVariant: ProductVariant) {
      if (currVariant?.info?.price === undefined && currVariant?.info?.priceAfterDiscount === undefined) {
         return (
            <div className={styles["price"]}>
               <Skeleton animation="wave" width={100} height={25} className="me-2" />
               <Skeleton animation="wave" width={150} height={24} />
            </div>
         );
      } else if (currVariant?.info?.price === 0 && currVariant?.info?.priceAfterDiscount === 0) {
         return (
            <div className={classNames(styles["price"], "text-danger")}>
               <span>Giá: Liên hệ</span>
            </div>
         );
      } else if (currVariant?.info?.price && currVariant?.info?.priceAfterDiscount) {
         const percentDiscount = 100 - (currVariant.info.priceAfterDiscount * 100) / currVariant.info.price;
         return (
            <div className={styles["price"]}>
               <div className={styles["discount-price"]}>
                  {currency(currVariant.info.priceAfterDiscount || 0)}
                  <span>đ</span>
               </div>
               <div className="d-flex align-items-center mt-1 mt-md-1">
                  <span className={styles["real-price"]}>{currency(currVariant.info.price || 0)}đ</span>
                  {currVariant.info.priceAfterDiscount && <div className={styles["percent-discount"]}>{Math.round(percentDiscount ?? 0)}%</div>}
               </div>
            </div>
         );
      } else {
         return (
            <div className={styles["price"]}>
               <div className={styles["discount-price"]}>
                  {currency(currVariant.info.price || 0)}
                  <span>đ</span>
               </div>
            </div>
         );
      }
   }
   function renderVariantOption(variant: IProductVariantInfo) {
      return (
         <>
            {variant.option01 && <span>{variant.option01}</span>}
            {variant.option02 && <span>{variant.option02}</span>}
            {/* {variant.option03 && <span>{variant.option03}</span>} */}
         </>
      );
   }

   return (
      <div className={styles["product-item"]}>
         <div className={styles["product-item-body"]}>
            <div className={styles["wrap-checkbox"]}>
               <div
                  className={`${isSelected ? classNames(styles["checkbox"], styles["checked"]) : styles["checkbox"]} `}
                  onClick={() => {
                     if (isEnable) {
                        onCheckOrderItem(cartItem, productItem);
                     }
                  }}
               >
                  {/* {isCalculating && <CircularProgress size={12} />} */}
               </div>
            </div>
            <Link href={`/shop/${productItem.__product.info.slug}`}>
               <div className={styles["img"]}>
                  <img
                     alt="product-avatar"
                     src={
                        !productItem?.__product?.__images || productItem.__product.__images.length <= 0
                           ? ""
                           : productItem.__product.__images[0].$url5And6ColResizeMax
                     }
                     className={styles["product-avatar"]}
                  />
               </div>
            </Link>
            <div className={classNames(styles["product-info"])}>
               <div className="d-flex justify-content-between align-items-center mb-2">
                  <Link href={`/shop/${productItem.__product.info.slug}`}>
                     <h3 className={styles["product-name"]}>{productItem?.__product?.info?.title ?? ""}</h3>
                  </Link>
                  <div
                     className={styles["bookmark"]}
                     onClick={async () => {
                        if (isBookmark) {
                           setIsBookmark(false);
                           toggleBookmarkProduct(cartItem, productItem.__product, false);
                        } else {
                           setIsBookmark(true);
                           toggleBookmarkProduct(cartItem, productItem.__product, true);
                        }
                     }}
                  >
                     {isBookmark ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="#FF6347">
                           <path
                              d="M12 15V2C12 1.44772 11.5523 1 11 1H1.80005C1.24776 1 0.800049 1.44772 0.800049 2V15L6.40021 9.93608L12 15Z"
                              stroke="#FF6347"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
                     ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
                           <path
                              d="M12 15V2C12 1.44772 11.5523 1 11 1H1.80005C1.24776 1 0.800049 1.44772 0.800049 2V15L6.40021 9.93608L12 15Z"
                              stroke="#252A2B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
                     )}
                  </div>
               </div>
               <div
                  className={classNames(styles["product-variant"])}
                  //  onClick={() => onSelectVariant(productItem)}
               >
                  <span className={styles["label"]}>Đặc tính:</span>
                  <span className={styles["value"]}>{renderVariantOption(productItem.__variant?.info)}</span>
               </div>
               {/* <div className={styles["voucher-tag"]}>{renderVoucherView()}</div> */}
               <div className="d-flex justify-content-between">
                  {renderQuantityBox(isEnable, cartItem, productItem)}
                  {caculatePriceItem(productItem.__variant)}
               </div>
               <div className={styles["action"]}>
                  <Tooltip
                     open={Boolean(showTooltipDelete[productItem._id])}
                     ref={tooltipDeleteRef}
                     arrow
                     classes={{
                        tooltip: "tooltip-delete",
                        arrow: "tooltip-arrow",
                     }}
                     disableHoverListener
                     disableFocusListener
                     disableTouchListener
                     placement="bottom-end"
                     title={
                        <div className="content-tooltip">
                           <div className="title">Xác nhận xoá?</div>
                           <div className="des text-center">
                              Bạn có chắc <br /> muốn xoá sản phẩm này?
                           </div>
                           <div className="wrap-button">
                              <button
                                 className="btn btn-outline"
                                 onClick={() => {
                                    handleToggleDialogDelete(productItem._id);
                                 }}
                              >
                                 Huỷ
                              </button>
                              <button
                                 className="btn btn-delete"
                                 onClick={() => {
                                    onRemoveProductFromCart(productItem._id);
                                    // tooltipDeleteRef.current?.close?.();
                                    handleToggleDialogDelete(productItem._id);
                                 }}
                              >
                                 Xoá
                              </button>
                           </div>
                        </div>
                     }
                  >
                     <button className={styles["btn-remove"]} onClick={() => handleToggleDialogDelete(productItem._id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                           <path
                              d="M5.00004 13.333C4.69449 13.333 4.43291 13.2242 4.21532 13.0066C3.99773 12.789 3.88893 12.5275 3.88893 12.2219V4.99967H3.33337V3.88856H6.11115V3.33301H9.44448V3.88856H12.2223V4.99967H11.6667V12.2219C11.6667 12.5275 11.5579 12.789 11.3403 13.0066C11.1227 13.2242 10.8612 13.333 10.5556 13.333H5.00004ZM10.5556 4.99967H5.00004V12.2219H10.5556V4.99967ZM6.11115 11.1108H7.22226V6.11079H6.11115V11.1108ZM8.33337 11.1108H9.44448V6.11079H8.33337V11.1108Z"
                              fill="#9F9F9F"
                           />
                        </svg>{" "}
                     </button>
                  </Tooltip>
               </div>
            </div>
         </div>

         {!isEnable && (
            <div className="row mt-3">
               <span className="text-danger">Sản phẩm tạm thời không có sẵn</span>
            </div>
         )}
      </div>
   );
}

/* eslint-plugin-disable react */
/*eslint-disable */

/**
 * Variable(s)
 */
import { resizeImage } from "utils/resizeImage";
import { apiDomain, } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";

/**
 * Model(s)
 */
import { Product } from "./product.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete"
}
export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440"
}

export enum EStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others"
}

export interface IProductImage extends Model {
   ["info"]?: {
      ["container"]?: string | null;
      ["domain"]?: string | null;
      ["originalName"]?: string | null;
      ["protocol"]?: string | null;
      ["size"]?: number | null;
      ["extension"]?: string | null;
      ["fileType"]?: string | null;
      ["position"]?: number | null;
   };
   ["options"]?: {
      ["status"]?: EStatus;
      ["storageProvider"]?: EStorageProvider;
   };
   ["productId"]?: string | null;
   ["$url"]?: any | null;

   ["$url64ResizeMax"]?: string;
   ["$url5And6ColResizeMax"]?: string;
   ["$url3And4ColResizeMax"]?: string;
   ["$url2ColResizeMax"]?: string;
   ["$url1ColResizeMax"]?: string;
}

export class ProductImage extends Model<IProductImage> implements IProductImage {
   public ["info"]!: {
      ["container"]?: string | null;
      ["domain"]?: string | null;
      ["originalName"]?: string | null;
      ["protocol"]?: string | null;
      ["size"]?: number | null;
      ["extension"]?: string | null;
      ["fileType"]?: string | null;
      ["position"]?: number | null;
      ["isMigrated"]?: boolean;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
      ["storageProvider"]?: EStorageProvider;
      ["isMigrated"]?: boolean;
   };
   public ["productId"]!: string | null;

   public ["__product"]!: Product | null;

   public ["$url"]?: any | null;

   public ["$url64ResizeMax"]?: string;
   public ["$url5And6ColResizeMax"]?: string;
   public ["$url3And4ColResizeMax"]?: string;
   public ["$url2ColResizeMax"]?: string;
   public ["$url1ColResizeMax"]?: string;

   constructor(data?: IProductImage) {
      super();

      Object.assign(this, data);

      const { $url, $url64ResizeMax, $url256ResizeMax, $url384ResizeMax, $url768ResizeMax, $url1024ResizeMax } = resizeImage(this._id, this.info, this.info?.isMigrated ?? this.options?.isMigrated)
      this.$url = $url
      this.$url64ResizeMax = $url64ResizeMax
      this.$url5And6ColResizeMax = $url256ResizeMax
      this.$url3And4ColResizeMax = $url384ResizeMax
      this.$url2ColResizeMax = $url768ResizeMax
      this.$url1ColResizeMax = $url1024ResizeMax
   }

   /**
    * Get image by image ID
    * @param id
    * @returns
    */
   public static async getProductImageById(id: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product/image`, {
            ["method"]: "GET",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: encodeURI(id)
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["productImage"]: IProductImage;
         }>;

         return new ProductImage(payload.data.productImage);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   /**
    * Get product images by product ID
    * @param id
    * @returns
    */
   public static async getProductImagesByProductId(id: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product/images`, {
            ["method"]: "GET",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: encodeURI(id)
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["productImages"]: IProductImage[];
         }>;

         return payload.data.productImages.map(producImageJSON => new ProductImage(producImageJSON));
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
   /**
    * Get full image URL
    */
   // get $url() {
   //     return `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
   // }
}

export default ProductImage;

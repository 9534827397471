/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { IModel, Paginate, ModelError, TModelError, TPayload, TPaginate } from "./model";
import { ActualWork } from "./actualWork.model";
import Article from "./article.model";
import ProductVariant from "./productVariant.model";
import Community from "./community.model";
import { ActualWorkMedia } from "./actualWorkMedia.model";
import ArticleMedia from "./articleMedia.model";
import CommunityMedia from "./communityMedia.model";
import SocialFeedMedia from "./socialFeedMedia.model";
import SocialFeed from "./socialFeed.model";
import Product from "./product.model";
import QnA from "./qna.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}
export enum EUserBookmarkTypes {
   construction = "construction",
   product = "product",
   blog = "blog",
   communityPost = "communityPost",

   productMedia = "productMedia",

   // Photo
   articleMedia = "articleMedia",
   actualWorkMedia = "actualWorkMedia",
   communityPostMedia = "communityPostMedia",

   socialFeed = "socialFeed",
   qna = "qna",
}

export interface IUserBookmark extends Model {
   ["info"]?: {
      ["openId"]?: string;
      ["openType"]?: EUserBookmarkTypes | null;
   };
   ["options"]?: {
      ["status"]?: EStatus;
   };

   ["__item"]?: Product | ActualWork | Article | Community | ArticleMedia | ActualWorkMedia | CommunityMedia | SocialFeed | QnA;
}

export class UserBookmark extends Model<IUserBookmark> implements IUserBookmark {
   public ["info"]!: {
      ["openId"]?: string;
      ["openType"]?: EUserBookmarkTypes | null;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
   };

   public ["__item"]!: Product | ActualWork | Article | Community | ActualWorkMedia | ArticleMedia | CommunityMedia | SocialFeed | QnA;

   constructor(data?: IUserBookmark) {
      super(data);

      Object.assign(this, data);
      if (
         this.__item &&
         !(this.__item instanceof ActualWork) &&
         !(this.__item instanceof Product) &&
         !(this.__item instanceof Article) &&
         !(this.__item instanceof Community) &&
         !(this.__item instanceof ActualWorkMedia) &&
         !(this.__item instanceof CommunityMedia) &&
         !(this.__item instanceof ArticleMedia) &&
         !(this.__item instanceof SocialFeed) &&
         !(this.__item instanceof QnA)
      ) {
         switch (this.info.openType) {
            case EUserBookmarkTypes.construction:
               this.__item = new ActualWork(this.__item);
               break;
            case EUserBookmarkTypes.product:
               this.__item = new Product(this.__item);
               break;
            case EUserBookmarkTypes.blog:
               this.__item = new Article(this.__item);
               break;
            case EUserBookmarkTypes.communityPost:
               this.__item = new Community(this.__item);
               break;
            case EUserBookmarkTypes.actualWorkMedia:
               this.__item = new ActualWorkMedia(this.__item);
               break;
            case EUserBookmarkTypes.communityPostMedia:
               this.__item = new CommunityMedia(this.__item);
               break;
            case EUserBookmarkTypes.articleMedia:
               this.__item = new ArticleMedia(this.__item);
               break;
            case EUserBookmarkTypes.socialFeed:
               this.__item = new SocialFeed(this.__item);
               if (this.__item?.__thumbnailMedia) {
                  this.__item.__thumbnailMedia = new SocialFeedMedia(this.__item.__thumbnailMedia);
               }
               break;
            case EUserBookmarkTypes.qna:
               this.__item = new QnA(this.__item);
               break;
            default:
               break;
         }
      }
   }
   /**
    * Get the list of user address store in paginate
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async getUserBookmarkPaginate(
      types: EUserBookmarkTypes[],
      page: number = 1,
      limit: number = 10,
      sort: "asc" | "desc" = "desc",
      sortBy: "createdAt" = "createdAt"
   ) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/bookmark/paginate`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["types"]: types.toString(),
               ["page"]: String(page),
               ["limit"]: String(limit),
               ["sort"]: sort,
               ["sort-by"]: sortBy,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IUserBookmark>;
         }>;

         const paginate = new Paginate(payload.data.paginator, UserBookmark);

         return paginate;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    * Get the list bookmark of user
    * @param userId
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async getProfileBookmarkPaginate(
      userId: string,
      types: EUserBookmarkTypes[],
      page: number = 1,
      limit: number = 10,
      sort: "asc" | "desc" = "desc",
      sortBy: "createdAt" = "createdAt",
      offset?: number
   ) {
      const response = await Model.fetchGet(
         null,
         {
            types: types.toString(),
            ...(!page ? undefined : { page: String(page) }),
            ...(!offset ? undefined : { offset: String(offset) }),
            limit: String(limit),
            sort: sort,
            "sort-by": sortBy,
            "profile-id": userId,
         },
         "profile/bookmark/paginate"
      );
      const payload = (await (response as unknown as Response).json()) as TPayload<{
         ["paginator"]: TPaginate<IUserBookmark>;
      }>;

      const paginate = new Paginate(payload.data.paginator, UserBookmark);

      return paginate;
   }

   /**
    *
    * @param openId
    * @param openType
    * @returns
    */
   public static async createUserBookmark(openId: string, openType: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/bookmark`, {
            ["method"]: "POST",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ["Authorization"]: `Bearer ${token}`,
            },
            ["body"]: JSON.stringify({
               ["openId"]: openId,
               ["openType"]: openType,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["newBookmark"]: IUserBookmark;
         }>;

         return new UserBookmark(payload.data.newBookmark);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    *
    * @param openId
    * @returns
    */
   public static async unBookmark(id: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/bookmark`, {
            ["method"]: "DELETE",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ["Authorization"]: `Bearer ${token}`,
               ["id"]: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["newBookmark"]: IUserBookmark;
         }>;

         return new UserBookmark(payload.data.newBookmark);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}

export default UserBookmark;

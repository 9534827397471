export const botToken = "7436611429:AAHERV8DTlnwiF-1D7AVCWdH4brDi3vy7sc";
export const botChatId = "1359425265";

export default class TeleBot {
   static async sendMessage(name: string, text?: any) {
      console.log(name, text);
      if (text && typeof text !== "string") {
         text = JSON.stringify(text);
      } else if (!text) {
         text = "";
      }
      name = `<blockquote>SpaceT Report: [${name || ""}]</blockquote>`;
      name += `\n<b>[URL]: <a href="${window.location.href}">${window.location.href}</a></b>`;
      name += `\n<b>[DEVICE]: ${JSON.stringify(navigator.userAgent)}</b>`;
      name += `\n<pre><code class="language-DATA">${text}</code></pre>`;
      return this.sendTelegramMessage(botChatId, name);
   }
   static async sendTelegramMessage(chatId: string, text: string) {
      const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
      const parse_mode = text.includes("<b>") ? "html" : "markdown";
      const res = await fetch(url, {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify({ chat_id: chatId, text, parse_mode }),
      });
      return await res.json();
   }

   static async checkStyle() {
      let text = `
         <b>bold</b>, <strong>bold</strong>
         <i>italic</i>, <em>italic</em>
         <u>underline</u>, <ins>underline</ins>
         <s>strikethrough</s>, <strike>strikethrough</strike>, <del>strikethrough</del>
         <span class="tg-spoiler">spoiler</span>, <tg-spoiler>spoiler</tg-spoiler>
         <b>bold <i>italic bold <s>italic bold strikethrough <span class="tg-spoiler">italic bold strikethrough spoiler</span></s> <u>underline italic bold</u></i> bold</b>
         <a href="http://www.example.com/">inline URL</a>
         <a href="tg://user?id=123456789">inline mention of a user</a>
         <tg-emoji emoji-id="5368324170671202286">👍</tg-emoji>
         <code>inline fixed-width code</code>
         <pre>pre-formatted fixed-width code block</pre>
         <pre><code class="language-python">pre-formatted fixed-width code block written in the Python programming language</code></pre>
         <blockquote>Block quotation started\nBlock quotation continued\nThe last line of the block quotation</blockquote>
         <blockquote expandable>Expandable block quotation started\nExpandable block quotation continued\nExpandable block quotation continued\nHidden by default part of the block quotation started\nExpandable block quotation continued\nThe last line of the block quotation</blockquote>
      `;
      return this.sendTelegramMessage(botChatId, text);
   }

   async getBotUpdates() {
      return fetch(`https://api.telegram.org/bot${botToken}/getUpdates`).then((response) => response.json());
   }
   async getUserTelegramId(uniqueString) {
      const { result } = await this.getBotUpdates();
      const messageUpdates = result.filter(({ message }) => message?.text !== undefined);
      const userUpdate = messageUpdates.find(({ message }) => message.text === `/start ${uniqueString}`);
      return userUpdate.message.from.id;
   }
}
